import React from 'react';
import { Form } from 'react-bootstrap';

export type CustomCheckboxType = 'checkbox' | 'switch' | 'radio';

export type CustomCheckboxProps = {
  checked?: boolean;
  isInvalid?: boolean;
  label?: string;
  name?: string;
  className?: string;
  type?: CustomCheckboxType;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const CustomCheckbox = ({
  checked = false,
  isInvalid,
  name,
  label,
  className,
  type = 'checkbox',
  onChange
}: CustomCheckboxProps) => {
  return (
    <Form.Check
      type={type}
      className={className}
      label={label}
      name={name}
      onChange={onChange}
      isInvalid={isInvalid}
      checked={checked}
    />
  );
};

export default CustomCheckbox;
