import React, { FC } from 'react';
import { useCallback } from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import useAuth from '../auth/useAuth';
import { useLocation, useNavigate, Link, LinkProps } from 'react-router-dom';

interface NativeLinkProps extends LinkProps {
  to: string;
}

const NativeLink: FC<NativeLinkProps> = ({ to, children, ...props }) => (
  <a {...props} href={to}>
    {children}
  </a>
);

interface NavBarItem {
  href: string;
  label: string;
  native?: boolean;
}

function MainNavigation() {
  const location = useLocation();
  const navigate = useNavigate();

  const links: NavBarItem[] = [
    {
      href: '',
      label: 'Dashboard'
    },
    {
      href: '/app/birds',
      label: 'Birds'
    },
    {
      href: '/app/reported-photos',
      label: 'Reported photos'
    },
    {
      href: '/app/manual-review',
      label: 'Manual review'
    },
    {
      href: '/app/inference',
      label: 'Inference'
    },
    {
      href: '/app/admins',
      label: 'Admins'
    },
    {
      href: '/app/editorial-feed',
      label: 'Editorial Feed',
      native: true
    },
    {
      href: '/app/feederless',
      label: 'Remote feeders',
      native: true
    }
  ];

  const { logOut } = useAuth();

  const handleLogout = useCallback(async () => {
    await logOut();
    navigate('/');
  }, [logOut, navigate]);

  return (
    <Navbar expand="xl" bg="primary" variant="dark" sticky="top">
      <Container>
        <Navbar.Brand href="/app">
          <img
            src="/static/logo-white.svg"
            height="30"
            className="d-inline-block align-top me-4"
            alt="BirdBuddy logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {links.map((link, i) => (
              <Nav.Link
                to={link.href}
                disabled={!link.href}
                key={i}
                active={link.href && location.pathname.includes(link.href)}
                as={link.native ? NativeLink : Link}
              >
                {link.label}
              </Nav.Link>
            ))}
          </Nav>
          <Nav>
            <Button variant="outline-light" onClick={handleLogout}>
              Log out
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavigation;
