import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AdminConnection = {
  readonly __typename?: 'AdminConnection';
  readonly edges: ReadonlyArray<AdminEdge>;
  readonly pageInfo: PageInfo;
};

export type AdminConnectionResult = AdminConnection | Problem;

export type AdminCreateInput = {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
  readonly role: UserRole;
};

export type AdminEdge = {
  readonly __typename?: 'AdminEdge';
  readonly node: AdminModel;
};

export type AdminEditInput = {
  readonly accessState: Scalars['Boolean'];
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
  readonly role: UserRole;
};

export type AdminFilterInput = {
  readonly sortBy?: Maybe<SortBy>;
  readonly sortDirection?: Maybe<SortDirection>;
};

export type AdminModel = Node & {
  readonly __typename?: 'AdminModel';
  readonly accessState: Scalars['Boolean'];
  readonly email: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly role: UserRole;
};

export type AdminReSendPasswordResult = AdminResendPassword | Problem;

export type AdminResendPassword = {
  readonly __typename?: 'AdminResendPassword';
  readonly sent: Scalars['Boolean'];
};

export type AdminResult = AdminModel | Problem;

export type AssetCreateInput = {
  readonly fileName: Scalars['String'];
  readonly key: Scalars['String'];
  readonly mimeType: Scalars['String'];
};

export type AssetCreateResult = AssetModel | Problem;

export type AssetModel = Node & {
  readonly __typename?: 'AssetModel';
  readonly contentType: Scalars['String'];
  readonly fileName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly url: Scalars['String'];
};

export type AssetUploadCreate = {
  readonly __typename?: 'AssetUploadCreate';
  readonly key: Scalars['String'];
  readonly url: Scalars['String'];
};

export type AssetUploadCreateInput = {
  readonly fileName: Scalars['String'];
  readonly mimeType: Scalars['String'];
};

export type AssetUploadCreateResult = AssetUploadCreate | Problem;

export type BadgeConnection = {
  readonly __typename?: 'BadgeConnection';
  readonly edges: ReadonlyArray<BadgeEdge>;
  readonly pageInfo: PageInfo;
};

export type BadgeConnectionResult = BadgeConnection | Problem;

export type BadgeCreateInput = {
  readonly badgeIconAssetId?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type BadgeEdge = {
  readonly __typename?: 'BadgeEdge';
  readonly node: BadgeModel;
};

export type BadgeEditInput = {
  readonly badgeIconAssetId?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type BadgeFilterInput = {
  readonly language?: Maybe<Scalars['String']>;
  readonly sortBy?: Maybe<SortBy>;
  readonly sortDirection?: Maybe<SortDirection>;
  readonly states?: Maybe<CompletionState>;
};

export type BadgeListModel = {
  readonly __typename?: 'BadgeListModel';
  readonly badges: ReadonlyArray<BadgeModel>;
};

export type BadgeListResult = BadgeListModel | Problem;

export type BadgeModel = Node & {
  readonly __typename?: 'BadgeModel';
  readonly badgeIconAsset?: Maybe<AssetModel>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly state: CompletionState;
  readonly translations?: Maybe<ReadonlyArray<BadgeTranslationModel>>;
};

export type BadgeRemoveResult = BirdsWithBadge | Problem | RemoveOperation;

export type BadgeResult = BadgeModel | Problem;

export type BadgeTranslationEditInput = {
  readonly description?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type BadgeTranslationModel = {
  readonly __typename?: 'BadgeTranslationModel';
  readonly description?: Maybe<Scalars['String']>;
  readonly languageId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly translationState: TranslationState;
};

export type BadgeTranslationResult = BadgeTranslationModel | Problem;

export enum BirdAssetType {
  Icon = 'ICON',
  Map = 'MAP',
  Profile = 'PROFILE',
  Song = 'SONG'
}

export type BirdAssetUploadCreateInput = {
  readonly fileName: Scalars['String'];
  readonly mimeType: Scalars['String'];
  readonly type: BirdAssetType;
};

export type BirdConnection = {
  readonly __typename?: 'BirdConnection';
  readonly edges: ReadonlyArray<BirdEdge>;
  readonly pageInfo: PageInfo;
};

export type BirdConnectionResult = BirdConnection | Problem;

export type BirdCreateInput = {
  readonly alternativeName?: Maybe<Scalars['String']>;
  readonly badges?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly birdIconAssetId?: Maybe<Scalars['String']>;
  readonly birdMapPicture?: Maybe<Scalars['String']>;
  readonly birdProfilePictureAssetId?: Maybe<Scalars['String']>;
  readonly definiteArticle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly foods?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly genderOfName?: Maybe<GenderOfName>;
  readonly groupingType?: Maybe<BirdGroupingType>;
  readonly indefiniteArticle?: Maybe<Scalars['String']>;
  readonly isUnofficialName: Scalars['Boolean'];
  readonly lengthFrom?: Maybe<Scalars['Float']>;
  readonly lengthTo?: Maybe<Scalars['Float']>;
  readonly locations?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly name: Scalars['String'];
  readonly scientificName?: Maybe<Scalars['String']>;
  readonly sentenceName: Scalars['String'];
  readonly sounds?: Maybe<ReadonlyArray<SoundInput>>;
  readonly weightFrom?: Maybe<Scalars['Float']>;
  readonly weightTo?: Maybe<Scalars['Float']>;
};

export type BirdEdge = {
  readonly __typename?: 'BirdEdge';
  readonly node: BirdModel;
};

export type BirdEditInput = {
  readonly alternativeName?: Maybe<Scalars['String']>;
  readonly badges?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly birdIconAssetId?: Maybe<Scalars['String']>;
  readonly birdMapPicture?: Maybe<Scalars['String']>;
  readonly birdProfilePictureAssetId?: Maybe<Scalars['String']>;
  readonly definiteArticle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly foods?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly genderOfName?: Maybe<GenderOfName>;
  readonly groupingType?: Maybe<BirdGroupingType>;
  readonly indefiniteArticle?: Maybe<Scalars['String']>;
  readonly isUnofficialName: Scalars['Boolean'];
  readonly lengthFrom?: Maybe<Scalars['Float']>;
  readonly lengthTo?: Maybe<Scalars['Float']>;
  readonly locations?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly name: Scalars['String'];
  readonly scientificName?: Maybe<Scalars['String']>;
  readonly sentenceName: Scalars['String'];
  readonly sounds?: Maybe<ReadonlyArray<SoundInput>>;
  readonly state?: Maybe<PublishedState>;
  readonly weightFrom?: Maybe<Scalars['Float']>;
  readonly weightTo?: Maybe<Scalars['Float']>;
};

export type BirdFindInput = {
  readonly language?: Maybe<Scalars['String']>;
  /** Uuid of location */
  readonly location?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly orderBy?: Maybe<SortByFilters>;
  readonly orderDirection?: Maybe<SortDirectionFilters>;
  readonly states?: Maybe<PublishedState>;
};

export enum BirdGroupingType {
  Bird = 'BIRD',
  BirdFamily = 'BIRD_FAMILY',
  BirdGenus = 'BIRD_GENUS',
  BirdOrder = 'BIRD_ORDER'
}

export type BirdModel = Node & {
  readonly __typename?: 'BirdModel';
  readonly ai?: Maybe<Scalars['Boolean']>;
  readonly alternativeName?: Maybe<Scalars['String']>;
  readonly badges?: Maybe<ReadonlyArray<BadgeModel>>;
  readonly base: BirdState;
  readonly birdIconAsset?: Maybe<AssetModel>;
  readonly birdProfilePictureAsset?: Maybe<AssetModel>;
  readonly createdAt: Scalars['DateTime'];
  readonly definiteArticle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly foods?: Maybe<ReadonlyArray<FoodModel>>;
  readonly genderOfName?: Maybe<GenderOfName>;
  readonly groupingType?: Maybe<BirdGroupingType>;
  readonly id: Scalars['ID'];
  readonly indefiniteArticle?: Maybe<Scalars['String']>;
  readonly isUnofficialName: Scalars['Boolean'];
  readonly lengthFrom?: Maybe<Scalars['Float']>;
  readonly lengthTo?: Maybe<Scalars['Float']>;
  readonly locations?: Maybe<ReadonlyArray<LocationModel>>;
  readonly mapPictureAsset?: Maybe<AssetModel>;
  readonly messageType?: Maybe<Scalars['String']>;
  readonly sentenceName: Scalars['String'];
  readonly sounds?: Maybe<ReadonlyArray<SoundModel>>;
  readonly speciesCommonName: Scalars['String'];
  readonly speciesScientificName?: Maybe<Scalars['String']>;
  readonly state: PublishedState;
  readonly translations?: Maybe<ReadonlyArray<BirdTranslationModel>>;
  readonly weightFrom?: Maybe<Scalars['Float']>;
  readonly weightTo?: Maybe<Scalars['Float']>;
};

export type BirdRequestAi = {
  readonly __typename?: 'BirdRequestAI';
  readonly birdId: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly guid: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly requested: Scalars['Boolean'];
  readonly state: RequestState;
};

export type BirdResult = BirdModel | Problem;

export type BirdSearchInput = {
  readonly birdLocation?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type BirdSearchModel = {
  readonly __typename?: 'BirdSearchModel';
  readonly birds: ReadonlyArray<BirdModel>;
};

export type BirdSearchResult = BirdSearchModel | Problem;

export enum BirdState {
  Completed = 'COMPLETED',
  Empty = 'EMPTY',
  Partially = 'PARTIALLY'
}

export type BirdTranslationEditInput = {
  readonly alternativeName?: Maybe<Scalars['String']>;
  readonly definiteArticle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly genderOfName?: Maybe<GenderOfName>;
  readonly indefiniteArticle?: Maybe<Scalars['String']>;
  readonly isUnofficialName: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly sentenceName: Scalars['String'];
};

export type BirdTranslationModel = {
  readonly __typename?: 'BirdTranslationModel';
  readonly alternativeName?: Maybe<Scalars['String']>;
  readonly definiteArticle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly genderOfName?: Maybe<GenderOfName>;
  readonly indefiniteArticle?: Maybe<Scalars['String']>;
  readonly isUnofficialName: Scalars['Boolean'];
  readonly languageId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly sentenceName: Scalars['String'];
  readonly translationState: TranslationState;
};

export type BirdsWithBadge = {
  readonly __typename?: 'BirdsWithBadge';
  readonly birds: ReadonlyArray<BirdModel>;
};

export type BirdsWithFood = {
  readonly __typename?: 'BirdsWithFood';
  readonly birds: ReadonlyArray<BirdModel>;
};

export type BirdsWithSoundLicense = {
  readonly __typename?: 'BirdsWithSoundLicense';
  readonly birds: ReadonlyArray<BirdModel>;
};

export enum CompletionState {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type CountryFlagModel = {
  readonly __typename?: 'CountryFlagModel';
  readonly flagUrl: Scalars['String'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CountryListModel = {
  readonly __typename?: 'CountryListModel';
  readonly countries: ReadonlyArray<CountryModel>;
};

export type CountryListResult = CountryListModel | Problem;

export type CountryModel = Node & {
  readonly __typename?: 'CountryModel';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type CreateFeederlessDeviceTutorialPostcardDto = {
  readonly deviceId: Scalars['String'];
  readonly inferenceResults: Scalars['String'];
  readonly mediaWithSpecies: ReadonlyArray<FeederlessDeviceTutorialPostcardMediaSpeciesDto>;
  readonly videoMediaId?: Maybe<Scalars['String']>;
};

export type CursorPageInfo = {
  readonly __typename?: 'CursorPageInfo';
  readonly endCursor?: Maybe<Scalars['DateTime']>;
  readonly hasNextPage?: Maybe<Scalars['Boolean']>;
  readonly hasPreviousPage?: Maybe<Scalars['Boolean']>;
  readonly startCursor?: Maybe<Scalars['DateTime']>;
};

export type DataExportConnection = {
  readonly __typename?: 'DataExportConnection';
  readonly edges: ReadonlyArray<DataExportEdge>;
  readonly pageInfo: PageInfo;
};

export type DataExportConnectionResult = DataExportConnection | Problem;

export type DataExportEdge = {
  readonly __typename?: 'DataExportEdge';
  readonly node: DataExportModel;
};

export type DataExportFile = {
  readonly __typename?: 'DataExportFile';
  readonly fileUrl: Scalars['String'];
};

export type DataExportFileResult = DataExportFile | Problem;

export type DataExportModel = Node & {
  readonly __typename?: 'DataExportModel';
  readonly admin: AdminModel;
  readonly createdAt: Scalars['DateTime'];
  readonly errorMessage?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly status: ExportStatus;
};

export type DataExportResult = DataExportModel | Problem;

export enum DataExportType {
  BadgesExportKey = 'BADGES_EXPORT_KEY',
  BirdsExportKey = 'BIRDS_EXPORT_KEY',
  FeederlessDeviceExportKey = 'FEEDERLESS_DEVICE_EXPORT_KEY',
  FoodExportKey = 'FOOD_EXPORT_KEY',
  LanguagesExportKey = 'LANGUAGES_EXPORT_KEY'
}


export enum DecisionType {
  Ignored = 'IGNORED',
  Removed = 'REMOVED'
}

export type DeviceInfoResult = FeederlessDeviceInfoModel | Problem;

export type DeviceResult = FeederlessDeviceModel | Problem;

export type EditorialFeedConnection = {
  readonly __typename?: 'EditorialFeedConnection';
  readonly edges: ReadonlyArray<EditorialFeedEdge>;
  readonly pageInfo: CursorPageInfo;
};

export type EditorialFeedConnectionResult = EditorialFeedConnection | Problem;

export type EditorialFeedEdge = {
  readonly __typename?: 'EditorialFeedEdge';
  readonly node: EditorialFeedModel;
};

export type EditorialFeedMediaModel = Node & {
  readonly __typename?: 'EditorialFeedMediaModel';
  readonly editorialFeedSpeciesList?: Maybe<ReadonlyArray<EditorialFeedSpeciesModel>>;
  readonly editorialFeedTagList?: Maybe<ReadonlyArray<EditorialFeedTagModel>>;
  readonly id: Scalars['ID'];
  readonly media: MediaModel;
};

export type EditorialFeedModel = Node & {
  readonly __typename?: 'EditorialFeedModel';
  readonly author: UserModel;
  readonly createdAt: Scalars['DateTime'];
  readonly editorialFeedMedias: ReadonlyArray<EditorialFeedMediaModel>;
  readonly feederLocationCity?: Maybe<Scalars['String']>;
  readonly feederLocationCountry: CountryModel;
  readonly id: Scalars['ID'];
  readonly mediaThumbnailId: Scalars['String'];
  readonly publishedAt?: Maybe<Scalars['DateTime']>;
  readonly scheduledFor?: Maybe<Scalars['DateTime']>;
  readonly unpublishedAt?: Maybe<Scalars['DateTime']>;
};

export enum EditorialFeedQueryTypeEnum {
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED',
  Unsorted = 'UNSORTED'
}

export type EditorialFeedResult = EditorialFeedModel | Problem;

export type EditorialFeedSpeciesModel = Node & {
  readonly __typename?: 'EditorialFeedSpeciesModel';
  readonly bird: BirdModel;
  readonly id: Scalars['ID'];
  readonly recognitionType: RecognitionType;
};

export type EditorialFeedTagEditInput = {
  readonly color: Scalars['String'];
  readonly name: Scalars['String'];
};

export type EditorialFeedTagListModel = {
  readonly __typename?: 'EditorialFeedTagListModel';
  readonly editorialFeedTags: ReadonlyArray<EditorialFeedTagModel>;
};

export type EditorialFeedTagListResult = EditorialFeedTagListModel | Problem;

export type EditorialFeedTagModel = Node & {
  readonly __typename?: 'EditorialFeedTagModel';
  readonly color: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EditorialFeedTagResult = EditorialFeedTagModel | Problem;

export enum ElementType {
  Admin = 'ADMIN',
  Badge = 'BADGE',
  Bird = 'BIRD',
  Food = 'FOOD',
  Language = 'LANGUAGE',
  SoundLicense = 'SOUND_LICENSE'
}

export enum ExportStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export enum FeederlessAssetType {
  CoverPhoto = 'COVER_PHOTO',
  InfoCoverPhoto = 'INFO_COVER_PHOTO',
  Landscape = 'LANDSCAPE',
  Map = 'MAP'
}

export type FeederlessDeviceInfoLandscapeModel = Node & {
  readonly __typename?: 'FeederlessDeviceInfoLandscapeModel';
  readonly id: Scalars['ID'];
  readonly landscapeInfoPhoto: AssetModel;
};

export type FeederlessDeviceInfoModel = Node & {
  readonly __typename?: 'FeederlessDeviceInfoModel';
  readonly altitude?: Maybe<Scalars['Int']>;
  readonly climate?: Maybe<Scalars['String']>;
  readonly continent?: Maybe<Scalars['String']>;
  readonly coverPhoto?: Maybe<AssetModel>;
  readonly description?: Maybe<Scalars['String']>;
  readonly deviceInfoLandscapeList?: Maybe<ReadonlyArray<FeederlessDeviceInfoLandscapeModel>>;
  readonly habitat?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly mapPhoto?: Maybe<AssetModel>;
  readonly websiteUrl?: Maybe<Scalars['String']>;
};

export type FeederlessDeviceModel = Node & {
  readonly __typename?: 'FeederlessDeviceModel';
  readonly author?: Maybe<UserModel>;
  readonly coverPhoto?: Maybe<AssetModel>;
  readonly deviceInfo?: Maybe<FeederlessDeviceInfoModel>;
  readonly diversity?: Maybe<Scalars['Int']>;
  readonly feederLocationCity?: Maybe<Scalars['String']>;
  readonly feederLocationCountry?: Maybe<CountryModel>;
  readonly feederLocationRegion?: Maybe<Scalars['String']>;
  readonly flagCode?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly organizationName?: Maybe<Scalars['String']>;
  readonly referralUnlockCount?: Maybe<Scalars['Int']>;
  readonly releasedAt?: Maybe<Scalars['DateTime']>;
  readonly scheduledPostcards: Scalars['Int'];
  readonly sourceFeederDeviceIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly status: FeederlessDeviceStatus;
  readonly tier: FeederlessDeviceTier;
  readonly timezoneCode?: Maybe<Scalars['String']>;
  readonly traffic?: Maybe<Scalars['Int']>;
  readonly tutorialPostcards?: Maybe<ReadonlyArray<FeederlessDeviceTutorialPostcardModel>>;
  readonly type: FeederlessDeviceType;
};

export enum FeederlessDeviceStatus {
  Connected = 'CONNECTED',
  Draft = 'DRAFT',
  Preview = 'PREVIEW',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export enum FeederlessDeviceTier {
  Free = 'FREE',
  Premium = 'PREMIUM',
  Referral = 'REFERRAL',
  Unspecified = 'UNSPECIFIED'
}

export type FeederlessDeviceTutorialPostcardMediaSpeciesDto = {
  readonly mediaId: Scalars['String'];
  readonly speciesIds: ReadonlyArray<Scalars['String']>;
};

export type FeederlessDeviceTutorialPostcardMediaSpeciesModel = Node & {
  readonly __typename?: 'FeederlessDeviceTutorialPostcardMediaSpeciesModel';
  readonly bird: BirdModel;
  readonly id: Scalars['ID'];
  readonly media: MediaModel;
};

export type FeederlessDeviceTutorialPostcardModel = Node & {
  readonly __typename?: 'FeederlessDeviceTutorialPostcardModel';
  readonly id: Scalars['ID'];
  readonly inferenceResults: Scalars['String'];
  readonly mediaSpecies?: Maybe<ReadonlyArray<FeederlessDeviceTutorialPostcardMediaSpeciesModel>>;
  readonly videoMediaId?: Maybe<Scalars['String']>;
};

export type FeederlessDeviceTutorialPostcardResult = FeederlessDeviceTutorialPostcardModel | Problem;

export enum FeederlessDeviceType {
  BirdBath = 'BIRD_BATH',
  Classic = 'CLASSIC',
  Hummingbird = 'HUMMINGBIRD',
  Platform = 'PLATFORM',
  Unspecified = 'UNSPECIFIED'
}

export type FeederlessPostcardConnection = {
  readonly __typename?: 'FeederlessPostcardConnection';
  readonly edges: ReadonlyArray<FeederlessPostcardEdge>;
  readonly pageInfo: CursorPageInfo;
};

export type FeederlessPostcardConnectionResult = FeederlessPostcardConnection | Problem;

export type FeederlessPostcardEdge = {
  readonly __typename?: 'FeederlessPostcardEdge';
  readonly node: FeederlessPostcardModel;
};

export type FeederlessPostcardMediaModel = Node & {
  readonly __typename?: 'FeederlessPostcardMediaModel';
  readonly feederlessPostcardSpeciesList?: Maybe<ReadonlyArray<FeederlessPostcardSpeciesModel>>;
  readonly id: Scalars['ID'];
  readonly media: MediaModel;
  readonly takenAt?: Maybe<Scalars['DateTime']>;
};

export type FeederlessPostcardModel = Node & {
  readonly __typename?: 'FeederlessPostcardModel';
  readonly createdAt: Scalars['DateTime'];
  readonly device: FeederlessDeviceModel;
  readonly favorite: Scalars['Boolean'];
  readonly feederlessPostcardMedias: ReadonlyArray<FeederlessPostcardMediaModel>;
  readonly id: Scalars['ID'];
  readonly inferenceResults: Scalars['String'];
  readonly mediaTakenAt: Scalars['DateTime'];
  readonly scheduledFor?: Maybe<Scalars['DateTime']>;
  readonly sentAt?: Maybe<Scalars['DateTime']>;
  readonly sourceFeederDeviceId: Scalars['String'];
};

export type FeederlessPostcardResult = FeederlessPostcardModel | Problem;

export type FeederlessPostcardSpeciesModel = Node & {
  readonly __typename?: 'FeederlessPostcardSpeciesModel';
  readonly bird: BirdModel;
  readonly id: Scalars['ID'];
  readonly recognitionType: RecognitionType;
};

export enum FeederlessPostcardState {
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Unsent = 'UNSENT'
}

export type FoodConnection = {
  readonly __typename?: 'FoodConnection';
  readonly edges: ReadonlyArray<FoodEdge>;
  readonly pageInfo: PageInfo;
};

export type FoodConnectionResult = FoodConnection | Problem;

export type FoodCreateInput = {
  readonly canBePutInFeeder?: Maybe<Scalars['Boolean']>;
  readonly foodIconAssetId?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type FoodEdge = {
  readonly __typename?: 'FoodEdge';
  readonly node: FoodModel;
};

export type FoodEditInput = {
  readonly canBePutInFeeder?: Maybe<Scalars['Boolean']>;
  readonly foodIconAssetId?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type FoodFilterInput = {
  readonly language?: Maybe<Scalars['String']>;
  readonly sortBy?: Maybe<SortBy>;
  readonly sortDirection?: Maybe<SortDirection>;
  readonly states?: Maybe<CompletionState>;
};

export type FoodListModel = {
  readonly __typename?: 'FoodListModel';
  readonly foods: ReadonlyArray<FoodModel>;
};

export type FoodListResult = FoodListModel | Problem;

export type FoodModel = Node & {
  readonly __typename?: 'FoodModel';
  readonly canBePutInFeeder: Scalars['Boolean'];
  readonly foodIconAsset?: Maybe<AssetModel>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly state: CompletionState;
  readonly translations?: Maybe<ReadonlyArray<FoodTranslationModel>>;
};

export type FoodRemoveResult = BirdsWithFood | Problem | RemoveOperation;

export type FoodResult = FoodModel | Problem;

export type FoodTranslationEditInput = {
  readonly name?: Maybe<Scalars['String']>;
};

export type FoodTranslationModel = {
  readonly __typename?: 'FoodTranslationModel';
  readonly languageId: Scalars['String'];
  readonly name?: Maybe<Scalars['String']>;
  readonly translationState: TranslationState;
};

export type FoodTranslationResult = FoodTranslationModel | Problem;

export enum GenderOfName {
  Common = 'COMMON',
  Female = 'FEMALE',
  Male = 'MALE',
  Neutral = 'NEUTRAL'
}

export type GoogleTranslationCreateResult = GoogleTranslationModel | Problem;

export type GoogleTranslationModel = {
  readonly __typename?: 'GoogleTranslationModel';
  readonly description: Scalars['String'];
};

export type HistoryConnection = {
  readonly __typename?: 'HistoryConnection';
  readonly edges: ReadonlyArray<HistoryEdge>;
  readonly pageInfo: PageInfo;
};

export type HistoryConnectionResult = HistoryConnection | Problem;

export type HistoryEdge = {
  readonly __typename?: 'HistoryEdge';
  readonly node: HistoryModel;
};

export type HistoryFindInput = {
  readonly adminId?: Maybe<Scalars['ID']>;
  readonly elementId?: Maybe<Scalars['String']>;
  readonly type?: Maybe<ElementType>;
};

export type HistoryModel = Node & {
  readonly __typename?: 'HistoryModel';
  readonly admin: AdminModel;
  readonly createdAt: Scalars['DateTime'];
  readonly description: Scalars['String'];
  readonly elementId: Scalars['String'];
  readonly entityName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly source: HistorySource;
  readonly type: ElementType;
};

export enum HistorySource {
  Api = 'API',
  Import = 'IMPORT'
}

export type LanguageConnectionModel = {
  readonly __typename?: 'LanguageConnectionModel';
  readonly edges: ReadonlyArray<LanguageEdge>;
  readonly pageInfo: PageInfo;
};

export type LanguageConnectionResult = LanguageConnectionModel | Problem;

export type LanguageCreateInput = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type LanguageEdge = {
  readonly __typename?: 'LanguageEdge';
  readonly node: LanguageModel;
};

export type LanguageEditInput = {
  readonly name: Scalars['String'];
  readonly state?: Maybe<PublishedState>;
};

export type LanguageFilterInput = {
  readonly language?: Maybe<Scalars['String']>;
  readonly sortBy?: Maybe<SortBy>;
  readonly sortDirection?: Maybe<SortDirection>;
  readonly states?: Maybe<PublishedState>;
};

export type LanguageListModel = {
  readonly __typename?: 'LanguageListModel';
  readonly languages: ReadonlyArray<LanguageModel>;
};

export type LanguageListResult = LanguageListModel | Problem;

export type LanguageModel = Node & {
  readonly __typename?: 'LanguageModel';
  readonly badgeLanguageState: LanguageState;
  readonly birdLanguageState: LanguageState;
  readonly foodLanguageState: LanguageState;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly state: PublishedState;
};

export type LanguageResult = LanguageModel | Problem;

export enum LanguageState {
  Fully = 'FULLY',
  Partial = 'PARTIAL'
}

export type LocationCreateInput = {
  readonly name: Scalars['String'];
};

export type LocationEditInput = {
  readonly name: Scalars['String'];
};

export type LocationListModel = {
  readonly __typename?: 'LocationListModel';
  readonly locations: ReadonlyArray<LocationModel>;
};

export type LocationListResult = LocationListModel | Problem;

export type LocationModel = Node & {
  readonly __typename?: 'LocationModel';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type LocationResult = LocationModel | Problem;

export type MeResult = AdminModel | Problem;

export enum MediaForReviewKind {
  MediaForContext = 'MEDIA_FOR_CONTEXT',
  MediaToReview = 'MEDIA_TO_REVIEW'
}

export enum MediaKind {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type MediaModel = Node & {
  readonly __typename?: 'MediaModel';
  readonly id: Scalars['ID'];
  readonly kind: MediaKind;
  readonly thumbnailUrl?: Maybe<Scalars['String']>;
  readonly url: Scalars['String'];
};

export type MediaReportConnection = {
  readonly __typename?: 'MediaReportConnection';
  readonly edges: ReadonlyArray<MediaReportEdge>;
  readonly pageInfo: PageInfo;
};

export type MediaReportConnectionResult = MediaReportConnection | Problem;

export type MediaReportEdge = {
  readonly __typename?: 'MediaReportEdge';
  readonly node: MediaReportModel;
};

export type MediaReportFilterInput = {
  /** This field has default value true to return default media reports that are resolved */
  readonly isMediaReportResolved?: Maybe<Scalars['Boolean']>;
  readonly orderBy?: Maybe<MediaReportOrderBy>;
  readonly orderDirection?: Maybe<MediaReportOrderByDirection>;
  readonly reason?: Maybe<MediaReportReason>;
  readonly recognitionType?: Maybe<RecognitionType>;
};

export type MediaReportListModel = {
  readonly __typename?: 'MediaReportListModel';
  readonly mediaReports: ReadonlyArray<MediaReportModel>;
};

export type MediaReportListResult = MediaReportListModel | Problem;

export type MediaReportModel = Node & {
  readonly __typename?: 'MediaReportModel';
  readonly author: UserModel;
  readonly createdAt: Scalars['DateTime'];
  readonly decision?: Maybe<DecisionType>;
  readonly decisionBy?: Maybe<AdminModel>;
  readonly decisionDate?: Maybe<Scalars['DateTime']>;
  readonly feederLocationCity?: Maybe<Scalars['String']>;
  readonly feederLocationCountry?: Maybe<Scalars['String']>;
  readonly feederName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly media: MediaModel;
  readonly mediaSpecies: ReadonlyArray<MediaSpeciesModel>;
  readonly numberOfReportsForMedia: Scalars['Float'];
  readonly reasons: ReadonlyArray<MediaReportReason>;
  readonly reportedBy: UserModel;
};

export enum MediaReportOrderBy {
  NrOfReports = 'NR_OF_REPORTS',
  Time = 'TIME'
}

export enum MediaReportOrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum MediaReportReason {
  InvolvesAPerson = 'INVOLVES_A_PERSON',
  NoBird = 'NO_BIRD',
  Nudity = 'NUDITY',
  Other = 'OTHER',
  SickBird = 'SICK_BIRD',
  Violence = 'VIOLENCE',
  WrongBird = 'WRONG_BIRD'
}

export type MediaReportResult = MediaReportModel | Problem;

export type MediaReportSummaryModel = {
  readonly __typename?: 'MediaReportSummaryModel';
  readonly currentMediaReport: MediaReportModel;
  readonly nextMediaReport?: Maybe<MediaReportModel>;
  readonly previousMediaReport?: Maybe<MediaReportModel>;
  readonly totalCount: Scalars['Float'];
};

export type MediaReportSummaryResult = MediaReportSummaryModel | Problem;

export type MediaReviewBirdModel = Node & {
  readonly __typename?: 'MediaReviewBirdModel';
  readonly bird: BirdModel;
  readonly id: Scalars['ID'];
};

export type MediaReviewConnection = {
  readonly __typename?: 'MediaReviewConnection';
  readonly edges: ReadonlyArray<MediaReviewEdge>;
  readonly pageInfo: PageInfo;
};

export type MediaReviewConnectionModel = Node & {
  readonly __typename?: 'MediaReviewConnectionModel';
  readonly id: Scalars['ID'];
  readonly media: MediaModel;
  readonly mediaForReviewKind: MediaForReviewKind;
};

export type MediaReviewConnectionResult = MediaReviewConnection | Problem;

export type MediaReviewEdge = {
  readonly __typename?: 'MediaReviewEdge';
  readonly node: MediaReviewModel;
};

export enum MediaReviewExportFilter {
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK'
}

export type MediaReviewExportInput = {
  readonly exportFilter: MediaReviewExportFilter;
};

export type MediaReviewExportModel = {
  readonly __typename?: 'MediaReviewExportModel';
  readonly path: Scalars['String'];
};

export type MediaReviewExportResult = MediaReviewExportModel | Problem;

export type MediaReviewFilterInput = {
  readonly birdName?: Maybe<Scalars['String']>;
  readonly isMediaReviewRecognized?: Maybe<Scalars['Boolean']>;
  /** This field has default value true to return default media review that are resolved */
  readonly isMediaReviewResolved?: Maybe<Scalars['Boolean']>;
  /** Name of location */
  readonly location?: Maybe<Scalars['String']>;
  readonly reviewerAction?: Maybe<MediaReviewIdentificationAction>;
};

export enum MediaReviewIdentificationAction {
  Escalate = 'ESCALATE',
  Ignore = 'IGNORE',
  Save = 'SAVE',
  SaveAndNotify = 'SAVE_AND_NOTIFY'
}

export type MediaReviewModel = Node & {
  readonly __typename?: 'MediaReviewModel';
  readonly author: UserModel;
  readonly bird?: Maybe<BirdModel>;
  readonly createdAt: Scalars['DateTime'];
  readonly feederLocationCity?: Maybe<Scalars['String']>;
  readonly feederLocationCountry?: Maybe<Scalars['String']>;
  readonly feederLocationLatitude?: Maybe<Scalars['Float']>;
  readonly feederLocationLongitude?: Maybe<Scalars['Float']>;
  readonly feederName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly mediaReviewBirds: ReadonlyArray<MediaReviewBirdModel>;
  readonly mediaReviewConnections: ReadonlyArray<MediaReviewConnectionModel>;
  readonly mediaReviewStatus: MediaReviewStatus;
  readonly providedName?: Maybe<Scalars['String']>;
  readonly reviewer?: Maybe<AdminModel>;
  readonly reviewerAction?: Maybe<MediaReviewIdentificationAction>;
};

export type MediaReviewResult = MediaReviewModel | Problem;

export enum MediaReviewStatus {
  CannotBeIdentified = 'CANNOT_BE_IDENTIFIED',
  Identified = 'IDENTIFIED',
  InReview = 'IN_REVIEW',
  Submitted = 'SUBMITTED',
  WaitingForBirdPublish = 'WAITING_FOR_BIRD_PUBLISH'
}

export type MediaReviewSummaryInput = {
  readonly birdName?: Maybe<Scalars['String']>;
  readonly countryName?: Maybe<Scalars['String']>;
  readonly isRecognized?: Maybe<Scalars['Boolean']>;
};

export type MediaReviewSummaryModel = {
  readonly __typename?: 'MediaReviewSummaryModel';
  readonly currentMediaReview: MediaReviewModel;
  readonly nextMediaReview?: Maybe<MediaReviewModel>;
  readonly previousMediaReview?: Maybe<MediaReviewModel>;
  readonly totalCount: Scalars['Float'];
};

export type MediaSpeciesModel = Node & {
  readonly __typename?: 'MediaSpeciesModel';
  readonly bird: BirdModel;
  readonly id: Scalars['ID'];
  readonly recognitionType: RecognitionType;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly addEditorialFeedMediaTag: EditorialFeedResult;
  readonly adminCreate: AdminResult;
  readonly adminEdit: AdminResult;
  readonly adminReSendPassword: AdminReSendPasswordResult;
  readonly assetCreate: AssetCreateResult;
  readonly badgeAssetUploadCreate: AssetUploadCreateResult;
  readonly badgeCreate: BadgeResult;
  readonly badgeEdit: BadgeResult;
  readonly badgeRemove: BadgeRemoveResult;
  readonly badgeTranslationEdit: BadgeTranslationResult;
  readonly birdAssetUploadCreate: AssetUploadCreateResult;
  readonly birdCreate: BirdResult;
  readonly birdEdit: BirdResult;
  readonly birdRemove: RemoveResult;
  readonly birdTranslationEdit: BirdResult;
  readonly createFeederlessDevice: DeviceResult;
  readonly createFeederlessDeviceInfo: DeviceInfoResult;
  readonly createFeederlessDeviceTutorialPostcard: FeederlessDeviceTutorialPostcardResult;
  readonly dataExportSchedule: DataExportResult;
  readonly editorialFeedTagCreate: EditorialFeedTagResult;
  readonly editorialFeedTagEdit: EditorialFeedTagResult;
  readonly editorialFeedTagRemove: RemoveResult;
  readonly foodAssetUploadCreate: AssetUploadCreateResult;
  readonly foodCreate: FoodResult;
  readonly foodEdit: FoodResult;
  readonly foodRemove: FoodRemoveResult;
  readonly foodTranslationEdit: FoodTranslationResult;
  readonly languageCreate: LanguageResult;
  readonly languageEdit: LanguageResult;
  readonly languageRemove: RemoveResult;
  readonly locationAdd: LocationResult;
  readonly locationEdit: LocationResult;
  readonly locationRemove: RemoveResult;
  readonly mediaReportDecide: MediaReportResult;
  readonly mediaReviewExport: MediaReviewExportResult;
  readonly mediaReviewIdentification: MediaReviewResult;
  readonly removeEditorialFeedMediaTag: EditorialFeedResult;
  readonly requestAIBird: RequestAiBirdResult;
  readonly requestNewBird: RequestNewBirdResult;
  readonly scheduleEditorialFeed: EditorialFeedResult;
  readonly scheduleFeederlessPostcard: FeederlessPostcardResult;
  readonly setEditorialFeedThumbnail: EditorialFeedResult;
  readonly soundLicenseCreate: SoundLicenseResult;
  readonly soundLicenseEdit: SoundLicenseResult;
  readonly soundLicenseRemove: SoundLicenseRemoveResult;
  readonly splitFeederlessPostcardMedia: FeederlessPostcardResult;
  readonly toggleFavoriteFeederlessPostcard: FeederlessPostcardResult;
  readonly unpublishEditorialFeed: EditorialFeedResult;
  readonly updateFeederlessDevice: DeviceResult;
  readonly updateFeederlessDeviceInfo: DeviceInfoResult;
  readonly updateFeederlessDeviceTutorialPostcard: FeederlessDeviceTutorialPostcardResult;
  readonly updateFeederlessPostcardMediaSpecies: FeederlessPostcardResult;
  readonly uploadFeederlessDeviceAsset: AssetUploadCreateResult;
};


export type MutationAddEditorialFeedMediaTagArgs = {
  editorialFeedMediaId: Scalars['String'];
  editorialFeedTagId: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationAdminCreateArgs = {
  adminCreateInput: AdminCreateInput;
};


export type MutationAdminEditArgs = {
  adminEditInput: AdminEditInput;
  id: Scalars['ID'];
};


export type MutationAdminReSendPasswordArgs = {
  id: Scalars['ID'];
};


export type MutationAssetCreateArgs = {
  assetCreateInput: AssetCreateInput;
};


export type MutationBadgeAssetUploadCreateArgs = {
  assetUploadCreateInput: AssetUploadCreateInput;
};


export type MutationBadgeCreateArgs = {
  badgeCreateInput: BadgeCreateInput;
};


export type MutationBadgeEditArgs = {
  badgeEditInput: BadgeEditInput;
  id: Scalars['ID'];
};


export type MutationBadgeRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationBadgeTranslationEditArgs = {
  badgeId: Scalars['ID'];
  badgeTranslationEditInput: BadgeTranslationEditInput;
  languageId: Scalars['ID'];
};


export type MutationBirdAssetUploadCreateArgs = {
  birdAssetUploadCreateInput: BirdAssetUploadCreateInput;
};


export type MutationBirdCreateArgs = {
  birdCreateInput: BirdCreateInput;
};


export type MutationBirdEditArgs = {
  birdEditInput: BirdEditInput;
  id: Scalars['ID'];
};


export type MutationBirdRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationBirdTranslationEditArgs = {
  birdTranslationEditInput: BirdTranslationEditInput;
  id: Scalars['ID'];
  languageId: Scalars['ID'];
};


export type MutationCreateFeederlessDeviceArgs = {
  coverPhotoAssetId?: Maybe<Scalars['String']>;
  diversity?: Maybe<Scalars['Int']>;
  feederLocationRegion?: Maybe<Scalars['String']>;
  flagCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  referralUnlockCount?: Maybe<Scalars['Int']>;
  releasedAt?: Maybe<Scalars['DateTime']>;
  sourceFeederDeviceIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  status: FeederlessDeviceStatus;
  tier: FeederlessDeviceTier;
  timezoneCode?: Maybe<Scalars['String']>;
  traffic?: Maybe<Scalars['Int']>;
  type: FeederlessDeviceType;
};


export type MutationCreateFeederlessDeviceInfoArgs = {
  altitude?: Maybe<Scalars['Int']>;
  climate?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  coverPhotoAssetId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  habitat?: Maybe<Scalars['String']>;
  landscapeInfoPhotoAssetIdList?: Maybe<ReadonlyArray<Scalars['String']>>;
  mapPhotoAssetId?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};


export type MutationCreateFeederlessDeviceTutorialPostcardArgs = {
  input: CreateFeederlessDeviceTutorialPostcardDto;
};


export type MutationDataExportScheduleArgs = {
  exportKeys?: Maybe<ReadonlyArray<DataExportType>>;
};


export type MutationEditorialFeedTagCreateArgs = {
  editorialFeedTagCreateInput: EditorialFeedTagEditInput;
};


export type MutationEditorialFeedTagEditArgs = {
  editorialFeedTagEditInput: EditorialFeedTagEditInput;
  id: Scalars['ID'];
};


export type MutationEditorialFeedTagRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationFoodAssetUploadCreateArgs = {
  assetUploadCreateInput: AssetUploadCreateInput;
};


export type MutationFoodCreateArgs = {
  foodCreateInput: FoodCreateInput;
};


export type MutationFoodEditArgs = {
  foodEditInput: FoodEditInput;
  id: Scalars['ID'];
};


export type MutationFoodRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationFoodTranslationEditArgs = {
  foodTranslationEditInput: FoodTranslationEditInput;
  id: Scalars['ID'];
  languageId: Scalars['ID'];
};


export type MutationLanguageCreateArgs = {
  languageCreateInput: LanguageCreateInput;
};


export type MutationLanguageEditArgs = {
  id: Scalars['ID'];
  languageEditInput: LanguageEditInput;
};


export type MutationLanguageRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationLocationAddArgs = {
  locationCreateInput: LocationCreateInput;
};


export type MutationLocationEditArgs = {
  id: Scalars['ID'];
  locationEditInput: LocationEditInput;
};


export type MutationLocationRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationMediaReportDecideArgs = {
  decisionType: DecisionType;
  id: Scalars['ID'];
};


export type MutationMediaReviewExportArgs = {
  mediaReviewExportInput: MediaReviewExportInput;
};


export type MutationMediaReviewIdentificationArgs = {
  birdIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  id: Scalars['ID'];
  reviewerAction: MediaReviewIdentificationAction;
};


export type MutationRemoveEditorialFeedMediaTagArgs = {
  editorialFeedMediaId: Scalars['String'];
  editorialFeedTagId: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationRequestAiBirdArgs = {
  requestAIBirdInput: RequestAiBirdInput;
};


export type MutationRequestNewBirdArgs = {
  requestNewBirdInput: RequestNewBirdInput;
};


export type MutationScheduleEditorialFeedArgs = {
  id: Scalars['ID'];
  scheduleFor: Scalars['DateTime'];
};


export type MutationScheduleFeederlessPostcardArgs = {
  id: Scalars['ID'];
  scheduleFor: Scalars['DateTime'];
};


export type MutationSetEditorialFeedThumbnailArgs = {
  id: Scalars['ID'];
  mediaId: Scalars['String'];
};


export type MutationSoundLicenseCreateArgs = {
  soundLicenseCreateInput: SoundLicenseCreateInput;
};


export type MutationSoundLicenseEditArgs = {
  id: Scalars['ID'];
  soundLicenseEditInput: SoundLicenseEditInput;
};


export type MutationSoundLicenseRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationSplitFeederlessPostcardMediaArgs = {
  feederlessPostcardMediaIds: ReadonlyArray<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationToggleFavoriteFeederlessPostcardArgs = {
  id: Scalars['ID'];
};


export type MutationUnpublishEditorialFeedArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateFeederlessDeviceArgs = {
  coverPhotoAssetId?: Maybe<Scalars['String']>;
  diversity?: Maybe<Scalars['Int']>;
  feederLocationRegion?: Maybe<Scalars['String']>;
  flagCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  referralUnlockCount?: Maybe<Scalars['Int']>;
  releasedAt?: Maybe<Scalars['DateTime']>;
  sourceFeederDeviceIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  status: FeederlessDeviceStatus;
  tier: FeederlessDeviceTier;
  timezoneCode?: Maybe<Scalars['String']>;
  traffic?: Maybe<Scalars['Int']>;
  type: FeederlessDeviceType;
};


export type MutationUpdateFeederlessDeviceInfoArgs = {
  altitude?: Maybe<Scalars['Int']>;
  climate?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  coverPhotoAssetId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  habitat?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  landscapeInfoPhotoAssetIdList?: Maybe<ReadonlyArray<Scalars['String']>>;
  mapPhotoAssetId?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateFeederlessDeviceTutorialPostcardArgs = {
  input: UpdateFeederlessDeviceTutorialPostcardDto;
};


export type MutationUpdateFeederlessPostcardMediaSpeciesArgs = {
  birdIds: ReadonlyArray<Scalars['String']>;
  feederlessPostcardMediaIds: ReadonlyArray<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationUploadFeederlessDeviceAssetArgs = {
  input: UploadFeederlessAssetInput;
};

export type NewBirdRequest = {
  readonly __typename?: 'NewBirdRequest';
  readonly birdName: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly guid: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly state: RequestState;
};

export type Node = {
  readonly id: Scalars['ID'];
};

export type PageInfo = {
  readonly __typename?: 'PageInfo';
  readonly allRecords: Scalars['Float'];
  readonly completedRecords?: Maybe<Scalars['Float']>;
  readonly currentPage: Scalars['Float'];
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
  readonly lastPage: Scalars['Float'];
};

export type Problem = {
  readonly __typename?: 'Problem';
  readonly items: ReadonlyArray<ProblemItem>;
};

export type ProblemItem = {
  readonly __typename?: 'ProblemItem';
  readonly field: Scalars['String'];
  readonly kind: ProblemItemKind;
};

export enum ProblemItemKind {
  /** Represents that value is not passing validation. */
  Invalid = 'INVALID',
  /** Represents that value is valid, but there was no results found for it. */
  NotFound = 'NOT_FOUND',
  /** Represents that value is valid, but it didn't match (ex. password isn't correct). */
  NoMatch = 'NO_MATCH',
  /** Represents that value is valid, but was already taken and it cant be used again. */
  Taken = 'TAKEN'
}

export enum PublishedState {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Ready = 'READY',
  Unpublished = 'UNPUBLISHED'
}

export type Query = {
  readonly __typename?: 'Query';
  readonly admin: AdminResult;
  readonly adminList: AdminConnectionResult;
  readonly badge: BadgeResult;
  readonly badgeList: BadgeListResult;
  readonly badgeListFiltered: BadgeConnectionResult;
  readonly bird: BirdResult;
  readonly birdFind: BirdConnectionResult;
  readonly birdSearch: BirdSearchResult;
  readonly countryFlagList: ReadonlyArray<CountryFlagModel>;
  readonly countryList: CountryListResult;
  readonly countrySearch: CountryListResult;
  readonly dataExportFile: DataExportFileResult;
  readonly dataExportList: DataExportConnectionResult;
  readonly editorialFeedList: EditorialFeedConnectionResult;
  readonly editorialFeedTag: EditorialFeedTagResult;
  readonly editorialFeedTagList: EditorialFeedTagListResult;
  readonly feederlessDevice: FeederlessDeviceModel;
  readonly feederlessDeviceList: ReadonlyArray<FeederlessDeviceModel>;
  readonly feederlessPostcardList: FeederlessPostcardConnectionResult;
  readonly food: FoodResult;
  readonly foodList: FoodListResult;
  readonly foodListFiltered: FoodConnectionResult;
  readonly googleTranslate: GoogleTranslationCreateResult;
  readonly historyFind: HistoryConnectionResult;
  readonly language: LanguageResult;
  readonly languageList: LanguageListResult;
  readonly languageListFiltered: LanguageConnectionResult;
  readonly locationList: LocationListResult;
  readonly me: MeResult;
  readonly mediaReportFind: MediaReportResult;
  readonly mediaReportHistory: MediaReportListResult;
  readonly mediaReportList: MediaReportConnectionResult;
  readonly mediaReportSummary: MediaReportSummaryResult;
  readonly mediaReviewFind: MediaReviewResult;
  readonly mediaReviewList: MediaReviewConnectionResult;
  readonly mediaReviewSummary: MediaReviewSummaryModel;
  readonly scheduledFeederlessPostcardList: FeederlessPostcardConnectionResult;
  readonly sentFeederlessPostcardList: FeederlessPostcardConnectionResult;
  readonly soundLicense: SoundLicenseResult;
  readonly soundLicenseList: SoundLicenseListResult;
  readonly soundLicenseListFiltered: SoundLicenseConnectionResult;
  readonly userFind: UserConnectionResult;
};


export type QueryAdminArgs = {
  id: Scalars['ID'];
};


export type QueryAdminListArgs = {
  adminFilterInput: AdminFilterInput;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryBadgeArgs = {
  id: Scalars['ID'];
};


export type QueryBadgeListFilteredArgs = {
  badgeFilterInput: BadgeFilterInput;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryBirdArgs = {
  id: Scalars['ID'];
};


export type QueryBirdFindArgs = {
  birdFindInput: BirdFindInput;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryBirdSearchArgs = {
  birdSearchInput: BirdSearchInput;
};


export type QueryCountrySearchArgs = {
  name: Scalars['String'];
};


export type QueryDataExportFileArgs = {
  exportFileSelector: DataExportType;
  id: Scalars['ID'];
};


export type QueryDataExportListArgs = {
  exportKeys?: Maybe<ReadonlyArray<DataExportType>>;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryEditorialFeedListArgs = {
  after?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  birdId?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  editorialFeedQueryType: EditorialFeedQueryTypeEnum;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['String']>;
  multipleBirds?: Maybe<Scalars['Boolean']>;
  tagIds?: Maybe<ReadonlyArray<Scalars['String']>>;
};


export type QueryEditorialFeedTagArgs = {
  id: Scalars['ID'];
};


export type QueryFeederlessDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryFeederlessPostcardListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  birdId?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['DateTime']>;
  hasVideo?: Maybe<Scalars['Boolean']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  isRecognized?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  numberOfMediaItems?: Maybe<Scalars['Int']>;
  sourceFeederDeviceId: Scalars['String'];
  state?: Maybe<FeederlessPostcardState>;
  to?: Maybe<Scalars['DateTime']>;
};


export type QueryFoodArgs = {
  id: Scalars['ID'];
};


export type QueryFoodListFilteredArgs = {
  foodFilterInput: FoodFilterInput;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryGoogleTranslateArgs = {
  translationCreateInput: TranslationCreateInput;
};


export type QueryHistoryFindArgs = {
  historyFindInput: HistoryFindInput;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryLanguageArgs = {
  id: Scalars['ID'];
};


export type QueryLanguageListFilteredArgs = {
  languageFilterInput: LanguageFilterInput;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryMediaReportFindArgs = {
  id: Scalars['ID'];
};


export type QueryMediaReportHistoryArgs = {
  mediaId: Scalars['ID'];
};


export type QueryMediaReportListArgs = {
  mediaReportFilterInput: MediaReportFilterInput;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryMediaReportSummaryArgs = {
  id: Scalars['ID'];
};


export type QueryMediaReviewFindArgs = {
  id: Scalars['ID'];
};


export type QueryMediaReviewListArgs = {
  mediaReviewFilterInput: MediaReviewFilterInput;
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryMediaReviewSummaryArgs = {
  id: Scalars['ID'];
  mediaReviewSummaryInput?: Maybe<MediaReviewSummaryInput>;
};


export type QueryScheduledFeederlessPostcardListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sourceFeederDeviceId: Scalars['String'];
};


export type QuerySentFeederlessPostcardListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sourceFeederDeviceId: Scalars['String'];
};


export type QuerySoundLicenseArgs = {
  id: Scalars['ID'];
};


export type QuerySoundLicenseListFilteredArgs = {
  page: Scalars['Int'];
  soundLicenseFilterInput: SoundLicenseFilterInput;
  take?: Maybe<Scalars['Int']>;
};


export type QueryUserFindArgs = {
  page: Scalars['Int'];
  take?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
};

export enum RecognitionType {
  AiRecognised = 'AI_RECOGNISED',
  ManualReview = 'MANUAL_REVIEW',
  Unspecified = 'UNSPECIFIED',
  UserSelected = 'USER_SELECTED'
}

export type RemoveOperation = {
  readonly __typename?: 'RemoveOperation';
  readonly removedItems: Scalars['Float'];
};

export type RemoveResult = Problem | RemoveOperation;

export type RequestAiBirdInput = {
  readonly birdId: Scalars['String'];
  readonly guid: Scalars['String'];
  /** Did User skip or request the bird */
  readonly requested: Scalars['Boolean'];
};

export type RequestAiBirdResult = BirdRequestAi | Problem;

export type RequestNewBirdInput = {
  readonly birdName: Scalars['String'];
  readonly guid: Scalars['String'];
};

export type RequestNewBirdResult = NewBirdRequest | Problem;

export enum RequestState {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Received = 'RECEIVED'
}

export enum SortBy {
  Name = 'NAME',
  Time = 'TIME'
}

export enum SortByFilters {
  Name = 'NAME',
  Time = 'TIME'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortDirectionFilters {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SoundInput = {
  readonly assetId: Scalars['String'];
  readonly authorName: Scalars['String'];
  readonly licenseId: Scalars['String'];
  readonly showInApp?: Maybe<Scalars['Boolean']>;
  readonly sourceUrl: Scalars['String'];
  readonly type: SoundType;
};

export type SoundLicenseConnectionModel = {
  readonly __typename?: 'SoundLicenseConnectionModel';
  readonly edges: ReadonlyArray<SoundLicenseEdge>;
  readonly pageInfo: PageInfo;
};

export type SoundLicenseConnectionResult = Problem | SoundLicenseConnectionModel;

export type SoundLicenseCreateInput = {
  readonly link: Scalars['String'];
  readonly name: Scalars['String'];
  readonly needsAttribution: Scalars['Boolean'];
};

export type SoundLicenseEdge = {
  readonly __typename?: 'SoundLicenseEdge';
  readonly node: SoundLicenseModel;
};

export type SoundLicenseEditInput = {
  readonly link?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly needsAttribution?: Maybe<Scalars['Boolean']>;
};

export type SoundLicenseFilterInput = {
  readonly sortBy?: Maybe<SortBy>;
  readonly sortDirection?: Maybe<SortDirection>;
};

export type SoundLicenseListModel = {
  readonly __typename?: 'SoundLicenseListModel';
  readonly soundLicenses: ReadonlyArray<SoundLicenseModel>;
};

export type SoundLicenseListResult = Problem | SoundLicenseListModel;

export type SoundLicenseModel = Node & {
  readonly __typename?: 'SoundLicenseModel';
  readonly id: Scalars['ID'];
  readonly link: Scalars['String'];
  readonly name: Scalars['String'];
  readonly needsAttribution: Scalars['Boolean'];
};

export type SoundLicenseRemoveResult = BirdsWithSoundLicense | Problem | RemoveOperation;

export type SoundLicenseResult = Problem | SoundLicenseModel;

export type SoundModel = Node & {
  readonly __typename?: 'SoundModel';
  readonly asset: AssetModel;
  readonly authorName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly license?: Maybe<SoundLicenseModel>;
  readonly showInApp: Scalars['Boolean'];
  readonly sourceUrl: Scalars['String'];
  readonly type: SoundType;
};

export enum SoundType {
  Call = 'CALL',
  Song = 'SONG'
}

export type TranslationCreateInput = {
  readonly description: Scalars['String'];
  readonly languageId: Scalars['String'];
};

export enum TranslationState {
  Empty = 'EMPTY',
  Fully = 'FULLY',
  Partial = 'PARTIAL'
}

export type UpdateFeederlessDeviceTutorialPostcardDto = {
  readonly id: Scalars['ID'];
  readonly inferenceResults: Scalars['String'];
  readonly mediaWithSpecies: ReadonlyArray<FeederlessDeviceTutorialPostcardMediaSpeciesDto>;
  readonly videoMediaId?: Maybe<Scalars['String']>;
};

export type UploadFeederlessAssetInput = {
  readonly fileName: Scalars['String'];
  readonly mimeType: Scalars['String'];
  readonly type: FeederlessAssetType;
};

export type UserConnection = {
  readonly __typename?: 'UserConnection';
  readonly edges: ReadonlyArray<UserEdge>;
  readonly pageInfo: PageInfo;
};

export type UserConnectionResult = Problem | UserConnection;

export type UserEdge = {
  readonly __typename?: 'UserEdge';
  readonly node: UserModel;
};

export type UserModel = Node & {
  readonly __typename?: 'UserModel';
  readonly id: Scalars['ID'];
  readonly username: Scalars['String'];
};

export enum UserRole {
  Admin = 'ADMIN',
  Editor = 'EDITOR'
}

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { readonly __typename?: 'Query' }
  & { readonly me: (
    { readonly __typename?: 'AdminModel' }
    & AdminFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type PageInfoFragmentFragment = (
  { readonly __typename?: 'PageInfo' }
  & Pick<PageInfo, 'currentPage' | 'hasNextPage' | 'hasPreviousPage' | 'lastPage' | 'allRecords' | 'completedRecords'>
);

export type ProblemFragmentFragment = (
  { readonly __typename: 'Problem' }
  & { readonly items: ReadonlyArray<(
    { readonly __typename?: 'ProblemItem' }
    & Pick<ProblemItem, 'field' | 'kind'>
  )> }
);

export type RemoveOperationFragmentFragment = (
  { readonly __typename: 'RemoveOperation' }
  & Pick<RemoveOperation, 'removedItems'>
);

export type AdminCreateMutationVariables = Exact<{
  adminCreateInput: AdminCreateInput;
}>;


export type AdminCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly adminCreate: (
    { readonly __typename?: 'AdminModel' }
    & AdminFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type AdminEditMutationVariables = Exact<{
  adminId: Scalars['ID'];
  adminEditInput: AdminEditInput;
}>;


export type AdminEditMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly adminEdit: (
    { readonly __typename: 'AdminModel' }
    & AdminFragmentFragment
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type AdminFragmentFragment = (
  { readonly __typename?: 'AdminModel' }
  & Pick<AdminModel, 'id' | 'email' | 'name' | 'accessState' | 'role'>
);

export type AdminListQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  adminFilterInput: AdminFilterInput;
}>;


export type AdminListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly adminList: (
    { readonly __typename?: 'AdminConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'AdminEdge' }
      & { readonly node: (
        { readonly __typename?: 'AdminModel' }
        & AdminFragmentFragment
      ) }
    )> }
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type AdminPasswordResetMutationVariables = Exact<{
  adminId: Scalars['ID'];
}>;


export type AdminPasswordResetMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly adminReSendPassword: (
    { readonly __typename: 'AdminResendPassword' }
    & Pick<AdminResendPassword, 'sent'>
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type AdminQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminQuery = (
  { readonly __typename?: 'Query' }
  & { readonly admin: (
    { readonly __typename?: 'AdminModel' }
    & AdminFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type AssetCreateMutationVariables = Exact<{
  assetCreateInput: AssetCreateInput;
}>;


export type AssetCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly assetCreate: (
    { readonly __typename?: 'AssetModel' }
    & AssetFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type AssetFragmentFragment = (
  { readonly __typename: 'AssetModel' }
  & Pick<AssetModel, 'id' | 'fileName' | 'contentType' | 'url'>
);

export type AssetUploadCreateFragmentFragment = (
  { readonly __typename: 'AssetUploadCreate' }
  & Pick<AssetUploadCreate, 'key' | 'url'>
);

export type BadgeAssetUploadCreateMutationVariables = Exact<{
  input: AssetUploadCreateInput;
}>;


export type BadgeAssetUploadCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly badgeAssetUploadCreate: (
    { readonly __typename?: 'AssetUploadCreate' }
    & AssetUploadCreateFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BadgeCreateMutationVariables = Exact<{
  input: BadgeCreateInput;
}>;


export type BadgeCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly badgeCreate: (
    { readonly __typename?: 'BadgeModel' }
    & BadgeFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BadgeEditMutationVariables = Exact<{
  id: Scalars['ID'];
  input: BadgeEditInput;
}>;


export type BadgeEditMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly badgeEdit: (
    { readonly __typename?: 'BadgeModel' }
    & BadgeFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BadgeFilteredQueryQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter: BadgeFilterInput;
}>;


export type BadgeFilteredQueryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly badgeListFiltered: (
    { readonly __typename: 'BadgeConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'BadgeEdge' }
      & { readonly node: (
        { readonly __typename?: 'BadgeModel' }
        & BadgeFragmentFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BadgeTranslationFragmentFragment = (
  { readonly __typename: 'BadgeTranslationModel' }
  & Pick<BadgeTranslationModel, 'languageId' | 'name' | 'description' | 'translationState'>
);

export type BadgeFragmentFragment = (
  { readonly __typename: 'BadgeModel' }
  & Pick<BadgeModel, 'id' | 'name' | 'description' | 'state'>
  & { readonly badgeIconAsset?: Maybe<(
    { readonly __typename?: 'AssetModel' }
    & AssetFragmentFragment
  )>, readonly translations?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'BadgeTranslationModel' }
    & BadgeTranslationFragmentFragment
  )>> }
);

export type BadgeListQueryVariables = Exact<{ [key: string]: never; }>;


export type BadgeListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly badgeList: (
    { readonly __typename: 'BadgeListModel' }
    & { readonly badges: ReadonlyArray<(
      { readonly __typename?: 'BadgeModel' }
      & BadgeFragmentFragment
    )> }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BadgeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BadgeQuery = (
  { readonly __typename?: 'Query' }
  & { readonly badge: (
    { readonly __typename?: 'BadgeModel' }
    & BadgeFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BadgeRemoveMutationVariables = Exact<{
  badgeId: Scalars['ID'];
}>;


export type BadgeRemoveMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly badgeRemove: (
    { readonly __typename?: 'BirdsWithBadge' }
    & { readonly birds: ReadonlyArray<(
      { readonly __typename?: 'BirdModel' }
      & BirdFragmentFragment
    )> }
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename?: 'RemoveOperation' }
    & RemoveOperationFragmentFragment
  ) }
);

export type BadgeTranslationEditMutationVariables = Exact<{
  badgeId: Scalars['ID'];
  languageId: Scalars['ID'];
  badgeTranslationEditInput: BadgeTranslationEditInput;
}>;


export type BadgeTranslationEditMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly badgeTranslationEdit: (
    { readonly __typename?: 'BadgeTranslationModel' }
    & BadgeTranslationFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BirdAssetUploadCreateMutationVariables = Exact<{
  birdAssetUploadCreateInput: BirdAssetUploadCreateInput;
}>;


export type BirdAssetUploadCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly birdAssetUploadCreate: (
    { readonly __typename: 'AssetUploadCreate' }
    & AssetUploadCreateFragmentFragment
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BirdCreateMutationVariables = Exact<{
  birdCreateInput: BirdCreateInput;
}>;


export type BirdCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly birdCreate: (
    { readonly __typename: 'BirdModel' }
    & BirdFragmentFragment
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BirdEditMutationVariables = Exact<{
  birdId: Scalars['ID'];
  birdEditInput: BirdEditInput;
}>;


export type BirdEditMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly birdEdit: (
    { readonly __typename: 'BirdModel' }
    & BirdFragmentFragment
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BirdFilteredQueryQueryVariables = Exact<{
  page?: Scalars['Int'];
  take?: Scalars['Int'];
  birdFindInput?: BirdFindInput;
}>;


export type BirdFilteredQueryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly birdFind: (
    { readonly __typename: 'BirdConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'BirdEdge' }
      & { readonly node: (
        { readonly __typename?: 'BirdModel' }
        & BirdFragmentFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BirdTranslationFragmentFragment = (
  { readonly __typename?: 'BirdTranslationModel' }
  & Pick<BirdTranslationModel, 'description' | 'languageId' | 'name' | 'alternativeName' | 'definiteArticle' | 'indefiniteArticle' | 'isUnofficialName' | 'genderOfName' | 'sentenceName' | 'translationState'>
);

export type BirdFragmentFragment = (
  { readonly __typename: 'BirdModel' }
  & Pick<BirdModel, 'id' | 'ai' | 'alternativeName' | 'base' | 'definiteArticle' | 'isUnofficialName' | 'indefiniteArticle' | 'genderOfName' | 'groupingType' | 'state' | 'sentenceName' | 'speciesCommonName' | 'speciesScientificName' | 'description' | 'messageType' | 'lengthFrom' | 'lengthTo' | 'weightFrom' | 'weightTo' | 'createdAt'>
  & { readonly badges?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'BadgeModel' }
    & BadgeFragmentFragment
  )>>, readonly foods?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'FoodModel' }
    & FoodFragmentFragment
  )>>, readonly birdIconAsset?: Maybe<(
    { readonly __typename?: 'AssetModel' }
    & AssetFragmentFragment
  )>, readonly birdProfilePictureAsset?: Maybe<(
    { readonly __typename?: 'AssetModel' }
    & AssetFragmentFragment
  )>, readonly translations?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'BirdTranslationModel' }
    & BirdTranslationFragmentFragment
  )>>, readonly locations?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'LocationModel' }
    & LocationFragmentFragment
  )>>, readonly mapPictureAsset?: Maybe<(
    { readonly __typename?: 'AssetModel' }
    & AssetFragmentFragment
  )>, readonly sounds?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'SoundModel' }
    & SoundFragmentFragment
  )>> }
);

export type BirdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BirdQuery = (
  { readonly __typename?: 'Query' }
  & { readonly bird: (
    { readonly __typename: 'BirdModel' }
    & BirdFragmentFragment
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BirdRemoveMutationVariables = Exact<{
  birdId: Scalars['ID'];
}>;


export type BirdRemoveMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly birdRemove: (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename: 'RemoveOperation' }
    & RemoveOperationFragmentFragment
  ) }
);

export type BirdSearchQueryVariables = Exact<{
  birdSearchInput?: BirdSearchInput;
}>;


export type BirdSearchQuery = (
  { readonly __typename?: 'Query' }
  & { readonly birdSearch: (
    { readonly __typename: 'BirdSearchModel' }
    & { readonly birds: ReadonlyArray<(
      { readonly __typename?: 'BirdModel' }
      & BirdSearchFieldsFragment
    )> }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BirdSearchFieldsFragment = (
  { readonly __typename?: 'BirdModel' }
  & Pick<BirdModel, 'id' | 'alternativeName' | 'speciesCommonName' | 'speciesScientificName' | 'state'>
);

export type BirdSuggestionQueryQueryVariables = Exact<{
  page?: Scalars['Int'];
  take?: Scalars['Int'];
  birdFindInput?: BirdFindInput;
}>;


export type BirdSuggestionQueryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly birdFind: (
    { readonly __typename: 'BirdConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'BirdEdge' }
      & { readonly node: (
        { readonly __typename?: 'BirdModel' }
        & BirdSuggestionFragmentFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type BirdSuggestionFragmentFragment = (
  { readonly __typename?: 'BirdModel' }
  & Pick<BirdModel, 'id' | 'alternativeName' | 'state' | 'speciesCommonName' | 'speciesScientificName'>
);

export type BirdTranslationEditMutationVariables = Exact<{
  birdId: Scalars['ID'];
  languageId: Scalars['ID'];
  birdTranslationEditInput: BirdTranslationEditInput;
}>;


export type BirdTranslationEditMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly birdTranslationEdit: (
    { readonly __typename: 'BirdModel' }
    & BirdFragmentFragment
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type DataExportFileQueryVariables = Exact<{
  dataExportId: Scalars['ID'];
  fileSelector: DataExportType;
}>;


export type DataExportFileQuery = (
  { readonly __typename?: 'Query' }
  & { readonly dataExportFile: (
    { readonly __typename: 'DataExportFile' }
    & Pick<DataExportFile, 'fileUrl'>
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type DataExportFragmentFragment = (
  { readonly __typename: 'DataExportModel' }
  & Pick<DataExportModel, 'id' | 'status' | 'createdAt' | 'errorMessage'>
  & { readonly admin: (
    { readonly __typename?: 'AdminModel' }
    & AdminFragmentFragment
  ) }
);

export type DataExportsListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type DataExportsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly dataExportList: (
    { readonly __typename: 'DataExportConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'DataExportEdge' }
      & { readonly node: (
        { readonly __typename?: 'DataExportModel' }
        & DataExportFragmentFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type DataExportScheduleMutationVariables = Exact<{ [key: string]: never; }>;


export type DataExportScheduleMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly dataExportSchedule: (
    { readonly __typename: 'DataExportModel' }
    & DataExportFragmentFragment
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type MediaReviewExportMutationVariables = Exact<{
  exportFilter: MediaReviewExportFilter;
}>;


export type MediaReviewExportMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly mediaReviewExport: (
    { readonly __typename?: 'MediaReviewExportModel' }
    & Pick<MediaReviewExportModel, 'path'>
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type FoodAssetUploadCreateMutationVariables = Exact<{
  input: AssetUploadCreateInput;
}>;


export type FoodAssetUploadCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly foodAssetUploadCreate: (
    { readonly __typename?: 'AssetUploadCreate' }
    & AssetUploadCreateFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type FoodCreateMutationVariables = Exact<{
  input: FoodCreateInput;
}>;


export type FoodCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly foodCreate: (
    { readonly __typename?: 'FoodModel' }
    & FoodFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type FoodEditMutationVariables = Exact<{
  id: Scalars['ID'];
  input: FoodEditInput;
}>;


export type FoodEditMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly foodEdit: (
    { readonly __typename?: 'FoodModel' }
    & FoodFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type FoodFilteredQueryQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter: FoodFilterInput;
}>;


export type FoodFilteredQueryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly foodListFiltered: (
    { readonly __typename: 'FoodConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'FoodEdge' }
      & { readonly node: (
        { readonly __typename?: 'FoodModel' }
        & FoodFragmentFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type FoodTranslationFragmentFragment = (
  { readonly __typename: 'FoodTranslationModel' }
  & Pick<FoodTranslationModel, 'languageId' | 'name' | 'translationState'>
);

export type FoodFragmentFragment = (
  { readonly __typename: 'FoodModel' }
  & Pick<FoodModel, 'id' | 'name' | 'canBePutInFeeder' | 'state'>
  & { readonly foodIconAsset?: Maybe<(
    { readonly __typename?: 'AssetModel' }
    & AssetFragmentFragment
  )>, readonly translations?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'FoodTranslationModel' }
    & FoodTranslationFragmentFragment
  )>> }
);

export type FoodListQueryVariables = Exact<{ [key: string]: never; }>;


export type FoodListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly foodList: (
    { readonly __typename: 'FoodListModel' }
    & { readonly foods: ReadonlyArray<(
      { readonly __typename?: 'FoodModel' }
      & FoodFragmentFragment
    )> }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type FoodQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FoodQuery = (
  { readonly __typename?: 'Query' }
  & { readonly food: (
    { readonly __typename?: 'FoodModel' }
    & FoodFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type FoodRemoveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FoodRemoveMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly foodRemove: (
    { readonly __typename?: 'BirdsWithFood' }
    & { readonly birds: ReadonlyArray<(
      { readonly __typename?: 'BirdModel' }
      & BirdFragmentFragment
    )> }
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename?: 'RemoveOperation' }
    & RemoveOperationFragmentFragment
  ) }
);

export type FoodTranslationEditMutationVariables = Exact<{
  foodId: Scalars['ID'];
  languageId: Scalars['ID'];
  foodTranslationEditInput: FoodTranslationEditInput;
}>;


export type FoodTranslationEditMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly foodTranslationEdit: (
    { readonly __typename?: 'FoodTranslationModel' }
    & FoodTranslationFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type GoogleTranslateQueryVariables = Exact<{
  translationCreateInput: TranslationCreateInput;
}>;


export type GoogleTranslateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly googleTranslate: (
    { readonly __typename: 'GoogleTranslationModel' }
    & GoogleTranslationFragmentFragment
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type GoogleTranslationFragmentFragment = (
  { readonly __typename: 'GoogleTranslationModel' }
  & Pick<GoogleTranslationModel, 'description'>
);

export type HistoryFragmentFragment = (
  { readonly __typename?: 'HistoryModel' }
  & Pick<HistoryModel, 'createdAt' | 'id' | 'source' | 'type' | 'elementId' | 'description' | 'entityName'>
  & { readonly admin: (
    { readonly __typename?: 'AdminModel' }
    & AdminFragmentFragment
  ) }
);

export type HistoryQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  historyFindInput: HistoryFindInput;
}>;


export type HistoryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly historyFind: (
    { readonly __typename: 'HistoryConnection' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'HistoryEdge' }
      & { readonly node: (
        { readonly __typename?: 'HistoryModel' }
        & HistoryFragmentFragment
      ) }
    )> }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type CreatelanguageMutationVariables = Exact<{
  input: LanguageCreateInput;
}>;


export type CreatelanguageMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly languageCreate: (
    { readonly __typename?: 'LanguageModel' }
    & LanguageFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type EditLanguageMutationVariables = Exact<{
  input: LanguageEditInput;
  id: Scalars['ID'];
}>;


export type EditLanguageMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly languageEdit: (
    { readonly __typename?: 'LanguageModel' }
    & LanguageFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type LanguageFragmentFragment = (
  { readonly __typename: 'LanguageModel' }
  & Pick<LanguageModel, 'id' | 'name' | 'badgeLanguageState' | 'birdLanguageState' | 'foodLanguageState' | 'state'>
);

export type LanguageListQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguageListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly languageList: (
    { readonly __typename?: 'LanguageListModel' }
    & { readonly languages: ReadonlyArray<(
      { readonly __typename?: 'LanguageModel' }
      & LanguageFragmentFragment
    )> }
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type LanguageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LanguageQuery = (
  { readonly __typename?: 'Query' }
  & { readonly language: (
    { readonly __typename?: 'LanguageModel' }
    & LanguageFragmentFragment
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type PaginatedLanguagesQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter: LanguageFilterInput;
}>;


export type PaginatedLanguagesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly languageListFiltered: (
    { readonly __typename: 'LanguageConnectionModel' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'LanguageEdge' }
      & { readonly node: (
        { readonly __typename?: 'LanguageModel' }
        & LanguageFragmentFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type RemoveLanguageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveLanguageMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly languageRemove: { readonly __typename: 'Problem' } | { readonly __typename: 'RemoveOperation' } }
);

export type LocationFragmentFragment = (
  { readonly __typename?: 'LocationModel' }
  & Pick<LocationModel, 'id' | 'name'>
);

export type LocationListQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationListQueryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly locationList: (
    { readonly __typename?: 'LocationListModel' }
    & { readonly locations: ReadonlyArray<(
      { readonly __typename?: 'LocationModel' }
      & LocationFragmentFragment
    )> }
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type CountriesListQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly countryList: (
    { readonly __typename?: 'CountryListModel' }
    & { readonly countries: ReadonlyArray<(
      { readonly __typename?: 'CountryModel' }
      & Pick<CountryModel, 'id' | 'name'>
    )> }
  ) | { readonly __typename?: 'Problem' } }
);

export type MediaFragmentFragment = (
  { readonly __typename?: 'MediaModel' }
  & Pick<MediaModel, 'id' | 'kind' | 'url'>
);

export type MediaReportDecideMutationVariables = Exact<{
  id: Scalars['ID'];
  decision: DecisionType;
}>;


export type MediaReportDecideMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly mediaReportDecide: (
    { readonly __typename?: 'MediaReportModel' }
    & MediaReportFieldsFragment
  ) | { readonly __typename?: 'Problem' } }
);

export type ReportedMediaSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportedMediaSummaryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly mediaReportSummary: (
    { readonly __typename?: 'MediaReportSummaryModel' }
    & Pick<MediaReportSummaryModel, 'totalCount'>
    & { readonly previousMediaReport?: Maybe<(
      { readonly __typename?: 'MediaReportModel' }
      & Pick<MediaReportModel, 'id'>
    )>, readonly currentMediaReport: (
      { readonly __typename?: 'MediaReportModel' }
      & MediaReportFieldsFragment
    ), readonly nextMediaReport?: Maybe<(
      { readonly __typename?: 'MediaReportModel' }
      & Pick<MediaReportModel, 'id'>
    )> }
  ) | { readonly __typename?: 'Problem' } }
);

export type MediaReportHistoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MediaReportHistoryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly mediaReportHistory: (
    { readonly __typename: 'MediaReportListModel' }
    & { readonly mediaReports: ReadonlyArray<(
      { readonly __typename?: 'MediaReportModel' }
      & MediaReportFieldsFragment
    )> }
  ) | (
    { readonly __typename: 'Problem' }
    & ProblemFragmentFragment
  ) }
);

export type MediaReviewFieldsFragment = (
  { readonly __typename?: 'MediaReviewModel' }
  & Pick<MediaReviewModel, 'id' | 'createdAt' | 'feederLocationCity' | 'feederLocationCountry' | 'feederLocationLatitude' | 'feederLocationLongitude' | 'feederName' | 'mediaReviewStatus' | 'providedName'>
  & { readonly author: (
    { readonly __typename?: 'UserModel' }
    & UserFragmentFragment
  ), readonly mediaReviewConnections: ReadonlyArray<(
    { readonly __typename?: 'MediaReviewConnectionModel' }
    & Pick<MediaReviewConnectionModel, 'mediaForReviewKind'>
    & { readonly media: (
      { readonly __typename?: 'MediaModel' }
      & MediaFragmentFragment
    ) }
  )>, readonly mediaReviewBirds: ReadonlyArray<(
    { readonly __typename?: 'MediaReviewBirdModel' }
    & Pick<MediaReviewBirdModel, 'id'>
    & { readonly bird: (
      { readonly __typename?: 'BirdModel' }
      & BirdSuggestionFragmentFragment
    ) }
  )>, readonly bird?: Maybe<(
    { readonly __typename?: 'BirdModel' }
    & BirdSuggestionFragmentFragment
  )> }
);

export type MediaReviewIdentificationFieldsFragment = (
  { readonly __typename?: 'MediaReviewModel' }
  & Pick<MediaReviewModel, 'id'>
  & { readonly reviewer?: Maybe<(
    { readonly __typename?: 'AdminModel' }
    & Pick<AdminModel, 'id' | 'name'>
  )>, readonly bird?: Maybe<(
    { readonly __typename?: 'BirdModel' }
    & BirdSuggestionFragmentFragment
  )>, readonly mediaReviewBirds: ReadonlyArray<(
    { readonly __typename?: 'MediaReviewBirdModel' }
    & Pick<MediaReviewBirdModel, 'id'>
    & { readonly bird: (
      { readonly __typename?: 'BirdModel' }
      & BirdSuggestionFragmentFragment
    ) }
  )> }
);

export type MediaReviewIdentificationMutationVariables = Exact<{
  mediaId: Scalars['ID'];
  birdIds?: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  reviewerAction: MediaReviewIdentificationAction;
}>;


export type MediaReviewIdentificationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly mediaReviewIdentification: (
    { readonly __typename?: 'MediaReviewModel' }
    & MediaReviewIdentificationFieldsFragment
  ) | { readonly __typename?: 'Problem' } }
);

export type MediaReviewSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
  mediaReviewSummaryInput?: Maybe<MediaReviewSummaryInput>;
}>;


export type MediaReviewSummaryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly mediaReviewSummary: (
    { readonly __typename?: 'MediaReviewSummaryModel' }
    & Pick<MediaReviewSummaryModel, 'totalCount'>
    & { readonly previousMediaReview?: Maybe<(
      { readonly __typename?: 'MediaReviewModel' }
      & Pick<MediaReviewModel, 'id'>
    )>, readonly currentMediaReview: (
      { readonly __typename?: 'MediaReviewModel' }
      & MediaReviewFieldsFragment
    ), readonly nextMediaReview?: Maybe<(
      { readonly __typename?: 'MediaReviewModel' }
      & Pick<MediaReviewModel, 'id'>
    )> }
  ) }
);

export type MediaReviewsFilteredQueryVariables = Exact<{
  page?: Scalars['Int'];
  take?: Scalars['Int'];
  filterInput?: MediaReviewFilterInput;
}>;


export type MediaReviewsFilteredQuery = (
  { readonly __typename?: 'Query' }
  & { readonly mediaReviewList: (
    { readonly __typename?: 'MediaReviewConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'MediaReviewEdge' }
      & { readonly node: (
        { readonly __typename?: 'MediaReviewModel' }
        & MediaReviewFieldsFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) | { readonly __typename?: 'Problem' } }
);

export type MediaSpeciesFragmentFragment = (
  { readonly __typename?: 'MediaSpeciesModel' }
  & Pick<MediaSpeciesModel, 'id' | 'recognitionType'>
  & { readonly bird: (
    { readonly __typename?: 'BirdModel' }
    & Pick<BirdModel, 'id' | 'speciesCommonName'>
    & { readonly birdIconAsset?: Maybe<(
      { readonly __typename?: 'AssetModel' }
      & Pick<AssetModel, 'id' | 'url'>
    )> }
  ) }
);

export type MediaReportFieldsFragment = (
  { readonly __typename?: 'MediaReportModel' }
  & Pick<MediaReportModel, 'id' | 'numberOfReportsForMedia' | 'decision' | 'createdAt' | 'feederLocationCountry' | 'feederName' | 'feederLocationCity' | 'reasons'>
  & { readonly author: (
    { readonly __typename?: 'UserModel' }
    & UserFragmentFragment
  ), readonly reportedBy: (
    { readonly __typename?: 'UserModel' }
    & UserFragmentFragment
  ), readonly decisionBy?: Maybe<(
    { readonly __typename?: 'AdminModel' }
    & Pick<AdminModel, 'email' | 'id' | 'name'>
  )>, readonly media: (
    { readonly __typename?: 'MediaModel' }
    & MediaFragmentFragment
  ), readonly mediaSpecies: ReadonlyArray<(
    { readonly __typename?: 'MediaSpeciesModel' }
    & MediaSpeciesFragmentFragment
  )> }
);

export type ReportedMediaFilteredQueryVariables = Exact<{
  page?: Scalars['Int'];
  take?: Scalars['Int'];
  mediaFindInput?: MediaReportFilterInput;
}>;


export type ReportedMediaFilteredQuery = (
  { readonly __typename?: 'Query' }
  & { readonly mediaReportList: (
    { readonly __typename?: 'MediaReportConnection' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'MediaReportEdge' }
      & { readonly node: (
        { readonly __typename?: 'MediaReportModel' }
        & MediaReportFieldsFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) | { readonly __typename?: 'Problem' } }
);

export type ReportedMediaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportedMediaQuery = (
  { readonly __typename?: 'Query' }
  & { readonly mediaReportFind: (
    { readonly __typename?: 'MediaReportModel' }
    & MediaReportFieldsFragment
  ) | { readonly __typename?: 'Problem' } }
);

export type SoundFragmentFragment = (
  { readonly __typename?: 'SoundModel' }
  & Pick<SoundModel, 'id' | 'authorName' | 'sourceUrl' | 'type' | 'showInApp'>
  & { readonly asset: (
    { readonly __typename?: 'AssetModel' }
    & AssetFragmentFragment
  ), readonly license?: Maybe<(
    { readonly __typename?: 'SoundLicenseModel' }
    & Pick<SoundLicenseModel, 'id' | 'name'>
  )> }
);

export type SoundLicenseListFilteredQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  filter: SoundLicenseFilterInput;
}>;


export type SoundLicenseListFilteredQuery = (
  { readonly __typename?: 'Query' }
  & { readonly soundLicenseListFiltered: (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename: 'SoundLicenseConnectionModel' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'SoundLicenseEdge' }
      & { readonly node: (
        { readonly __typename?: 'SoundLicenseModel' }
        & SoundLicenseFieldsFragment
      ) }
    )>, readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & PageInfoFragmentFragment
    ) }
  ) }
);

export type SoundLicenseCreateMutationVariables = Exact<{
  input: SoundLicenseCreateInput;
}>;


export type SoundLicenseCreateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly soundLicenseCreate: (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename?: 'SoundLicenseModel' }
    & SoundLicenseFieldsFragment
  ) }
);

export type SoundLicenseEditMutationVariables = Exact<{
  input: SoundLicenseEditInput;
  id: Scalars['ID'];
}>;


export type SoundLicenseEditMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly soundLicenseEdit: (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename?: 'SoundLicenseModel' }
    & SoundLicenseFieldsFragment
  ) }
);

export type SoundLicenseFieldsFragment = (
  { readonly __typename?: 'SoundLicenseModel' }
  & Pick<SoundLicenseModel, 'id' | 'link' | 'name' | 'needsAttribution'>
);

export type SoundLicenseListQueryVariables = Exact<{ [key: string]: never; }>;


export type SoundLicenseListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly soundLicenseList: (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename?: 'SoundLicenseListModel' }
    & { readonly soundLicenses: ReadonlyArray<(
      { readonly __typename?: 'SoundLicenseModel' }
      & Pick<SoundLicenseModel, 'id' | 'name'>
    )> }
  ) }
);

export type SoundLicenseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SoundLicenseQuery = (
  { readonly __typename?: 'Query' }
  & { readonly soundLicense: (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename?: 'SoundLicenseModel' }
    & SoundLicenseFieldsFragment
  ) }
);

export type SoundLicenseRemoveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SoundLicenseRemoveMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly soundLicenseRemove: (
    { readonly __typename?: 'BirdsWithSoundLicense' }
    & { readonly birds: ReadonlyArray<(
      { readonly __typename?: 'BirdModel' }
      & BirdFragmentFragment
    )> }
  ) | (
    { readonly __typename?: 'Problem' }
    & ProblemFragmentFragment
  ) | (
    { readonly __typename?: 'RemoveOperation' }
    & RemoveOperationFragmentFragment
  ) }
);

export type UserFragmentFragment = (
  { readonly __typename?: 'UserModel' }
  & Pick<UserModel, 'id' | 'username'>
);

export const PageInfoFragmentFragmentDoc = gql`
    fragment PageInfoFragment on PageInfo {
  currentPage
  hasNextPage
  hasPreviousPage
  hasNextPage
  lastPage
  allRecords
  completedRecords
}
    `;
export const ProblemFragmentFragmentDoc = gql`
    fragment ProblemFragment on Problem {
  __typename
  items {
    field
    kind
  }
}
    `;
export const RemoveOperationFragmentFragmentDoc = gql`
    fragment RemoveOperationFragment on RemoveOperation {
  __typename
  removedItems
}
    `;
export const AssetUploadCreateFragmentFragmentDoc = gql`
    fragment AssetUploadCreateFragment on AssetUploadCreate {
  __typename
  key
  url
}
    `;
export const AssetFragmentFragmentDoc = gql`
    fragment AssetFragment on AssetModel {
  __typename
  id
  fileName
  contentType
  url
}
    `;
export const BadgeTranslationFragmentFragmentDoc = gql`
    fragment BadgeTranslationFragment on BadgeTranslationModel {
  __typename
  languageId
  name
  description
  translationState
}
    `;
export const BadgeFragmentFragmentDoc = gql`
    fragment BadgeFragment on BadgeModel {
  __typename
  id
  name
  description
  state
  badgeIconAsset {
    ...AssetFragment
  }
  translations {
    ...BadgeTranslationFragment
  }
}
    ${AssetFragmentFragmentDoc}
${BadgeTranslationFragmentFragmentDoc}`;
export const FoodTranslationFragmentFragmentDoc = gql`
    fragment FoodTranslationFragment on FoodTranslationModel {
  __typename
  languageId
  name
  translationState
}
    `;
export const FoodFragmentFragmentDoc = gql`
    fragment FoodFragment on FoodModel {
  __typename
  id
  name
  canBePutInFeeder
  state
  foodIconAsset {
    ...AssetFragment
  }
  translations {
    ...FoodTranslationFragment
  }
}
    ${AssetFragmentFragmentDoc}
${FoodTranslationFragmentFragmentDoc}`;
export const BirdTranslationFragmentFragmentDoc = gql`
    fragment BirdTranslationFragment on BirdTranslationModel {
  description
  languageId
  name
  alternativeName
  definiteArticle
  indefiniteArticle
  isUnofficialName
  genderOfName
  sentenceName
  translationState
}
    `;
export const LocationFragmentFragmentDoc = gql`
    fragment LocationFragment on LocationModel {
  id
  name
}
    `;
export const SoundFragmentFragmentDoc = gql`
    fragment SoundFragment on SoundModel {
  asset {
    ...AssetFragment
  }
  license {
    id
    name
  }
  id
  authorName
  sourceUrl
  type
  showInApp
}
    ${AssetFragmentFragmentDoc}`;
export const BirdFragmentFragmentDoc = gql`
    fragment BirdFragment on BirdModel {
  __typename
  id
  ai
  alternativeName
  badges {
    ...BadgeFragment
  }
  base
  definiteArticle
  isUnofficialName
  indefiniteArticle
  genderOfName
  groupingType
  state
  foods {
    ...FoodFragment
  }
  sentenceName
  speciesCommonName
  speciesScientificName
  description
  birdIconAsset {
    ...AssetFragment
  }
  birdProfilePictureAsset {
    ...AssetFragment
  }
  translations {
    ...BirdTranslationFragment
  }
  locations {
    ...LocationFragment
  }
  mapPictureAsset {
    ...AssetFragment
  }
  messageType
  sounds {
    ...SoundFragment
  }
  lengthFrom
  lengthTo
  weightFrom
  weightTo
  createdAt
}
    ${BadgeFragmentFragmentDoc}
${FoodFragmentFragmentDoc}
${AssetFragmentFragmentDoc}
${BirdTranslationFragmentFragmentDoc}
${LocationFragmentFragmentDoc}
${SoundFragmentFragmentDoc}`;
export const BirdSearchFieldsFragmentDoc = gql`
    fragment BirdSearchFields on BirdModel {
  id
  alternativeName
  speciesCommonName
  speciesScientificName
  state
}
    `;
export const AdminFragmentFragmentDoc = gql`
    fragment AdminFragment on AdminModel {
  id
  email
  name
  accessState
  role
}
    `;
export const DataExportFragmentFragmentDoc = gql`
    fragment DataExportFragment on DataExportModel {
  __typename
  id
  status
  admin {
    ...AdminFragment
  }
  createdAt
  errorMessage
}
    ${AdminFragmentFragmentDoc}`;
export const GoogleTranslationFragmentFragmentDoc = gql`
    fragment GoogleTranslationFragment on GoogleTranslationModel {
  __typename
  description
}
    `;
export const HistoryFragmentFragmentDoc = gql`
    fragment HistoryFragment on HistoryModel {
  createdAt
  id
  admin {
    ...AdminFragment
  }
  source
  type
  elementId
  description
  entityName
}
    ${AdminFragmentFragmentDoc}`;
export const LanguageFragmentFragmentDoc = gql`
    fragment LanguageFragment on LanguageModel {
  __typename
  id
  name
  badgeLanguageState
  birdLanguageState
  foodLanguageState
  state
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on UserModel {
  id
  username
}
    `;
export const MediaFragmentFragmentDoc = gql`
    fragment MediaFragment on MediaModel {
  id
  kind
  url
}
    `;
export const BirdSuggestionFragmentFragmentDoc = gql`
    fragment BirdSuggestionFragment on BirdModel {
  id
  alternativeName
  state
  speciesCommonName
  speciesScientificName
}
    `;
export const MediaReviewFieldsFragmentDoc = gql`
    fragment MediaReviewFields on MediaReviewModel {
  id
  author {
    ...UserFragment
  }
  createdAt
  feederLocationCity
  feederLocationCountry
  feederLocationLatitude
  feederLocationLongitude
  feederName
  mediaReviewStatus
  providedName
  mediaReviewConnections {
    mediaForReviewKind
    media {
      ...MediaFragment
    }
  }
  mediaReviewBirds {
    id
    bird {
      ...BirdSuggestionFragment
    }
  }
  bird {
    ...BirdSuggestionFragment
  }
}
    ${UserFragmentFragmentDoc}
${MediaFragmentFragmentDoc}
${BirdSuggestionFragmentFragmentDoc}`;
export const MediaReviewIdentificationFieldsFragmentDoc = gql`
    fragment MediaReviewIdentificationFields on MediaReviewModel {
  id
  reviewer {
    id
    name
  }
  bird {
    ...BirdSuggestionFragment
  }
  mediaReviewBirds {
    id
    bird {
      ...BirdSuggestionFragment
    }
  }
}
    ${BirdSuggestionFragmentFragmentDoc}`;
export const MediaSpeciesFragmentFragmentDoc = gql`
    fragment MediaSpeciesFragment on MediaSpeciesModel {
  id
  bird {
    id
    speciesCommonName
    birdIconAsset {
      id
      url
    }
  }
  recognitionType
}
    `;
export const MediaReportFieldsFragmentDoc = gql`
    fragment MediaReportFields on MediaReportModel {
  id
  author {
    ...UserFragment
  }
  reportedBy {
    ...UserFragment
  }
  decisionBy {
    email
    id
    name
  }
  numberOfReportsForMedia
  decision
  media {
    ...MediaFragment
  }
  createdAt
  decision
  feederLocationCountry
  numberOfReportsForMedia
  feederName
  feederLocationCity
  reasons
  createdAt
  mediaSpecies {
    ...MediaSpeciesFragment
  }
}
    ${UserFragmentFragmentDoc}
${MediaFragmentFragmentDoc}
${MediaSpeciesFragmentFragmentDoc}`;
export const SoundLicenseFieldsFragmentDoc = gql`
    fragment SoundLicenseFields on SoundLicenseModel {
  id
  link
  name
  needsAttribution
}
    `;
export const MeDocument = gql`
    query me {
  me {
    ... on AdminModel {
      ...AdminFragment
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${AdminFragmentFragmentDoc}
${ProblemFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AdminCreateDocument = gql`
    mutation adminCreate($adminCreateInput: AdminCreateInput!) {
  adminCreate(adminCreateInput: $adminCreateInput) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on AdminModel {
      ...AdminFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${AdminFragmentFragmentDoc}`;
export type AdminCreateMutationFn = Apollo.MutationFunction<AdminCreateMutation, AdminCreateMutationVariables>;

/**
 * __useAdminCreateMutation__
 *
 * To run a mutation, you first call `useAdminCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateMutation, { data, loading, error }] = useAdminCreateMutation({
 *   variables: {
 *      adminCreateInput: // value for 'adminCreateInput'
 *   },
 * });
 */
export function useAdminCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminCreateMutation, AdminCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AdminCreateMutation, AdminCreateMutationVariables>(AdminCreateDocument, options);
      }
export type AdminCreateMutationHookResult = ReturnType<typeof useAdminCreateMutation>;
export type AdminCreateMutationResult = Apollo.MutationResult<AdminCreateMutation>;
export type AdminCreateMutationOptions = Apollo.BaseMutationOptions<AdminCreateMutation, AdminCreateMutationVariables>;
export const AdminEditDocument = gql`
    mutation adminEdit($adminId: ID!, $adminEditInput: AdminEditInput!) {
  adminEdit(id: $adminId, adminEditInput: $adminEditInput) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on AdminModel {
      ...AdminFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${AdminFragmentFragmentDoc}`;
export type AdminEditMutationFn = Apollo.MutationFunction<AdminEditMutation, AdminEditMutationVariables>;

/**
 * __useAdminEditMutation__
 *
 * To run a mutation, you first call `useAdminEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditMutation, { data, loading, error }] = useAdminEditMutation({
 *   variables: {
 *      adminId: // value for 'adminId'
 *      adminEditInput: // value for 'adminEditInput'
 *   },
 * });
 */
export function useAdminEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminEditMutation, AdminEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AdminEditMutation, AdminEditMutationVariables>(AdminEditDocument, options);
      }
export type AdminEditMutationHookResult = ReturnType<typeof useAdminEditMutation>;
export type AdminEditMutationResult = Apollo.MutationResult<AdminEditMutation>;
export type AdminEditMutationOptions = Apollo.BaseMutationOptions<AdminEditMutation, AdminEditMutationVariables>;
export const AdminListDocument = gql`
    query adminList($page: Int!, $perPage: Int!, $adminFilterInput: AdminFilterInput!) {
  adminList(page: $page, take: $perPage, adminFilterInput: $adminFilterInput) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on AdminConnection {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...AdminFragment
        }
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}
${AdminFragmentFragmentDoc}`;

/**
 * __useAdminListQuery__
 *
 * To run a query within a React component, call `useAdminListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      adminFilterInput: // value for 'adminFilterInput'
 *   },
 * });
 */
export function useAdminListQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AdminListQuery, AdminListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AdminListQuery, AdminListQueryVariables>(AdminListDocument, options);
      }
export function useAdminListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminListQuery, AdminListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AdminListQuery, AdminListQueryVariables>(AdminListDocument, options);
        }
export type AdminListQueryHookResult = ReturnType<typeof useAdminListQuery>;
export type AdminListLazyQueryHookResult = ReturnType<typeof useAdminListLazyQuery>;
export type AdminListQueryResult = Apollo.QueryResult<AdminListQuery, AdminListQueryVariables>;
export const AdminPasswordResetDocument = gql`
    mutation adminPasswordReset($adminId: ID!) {
  adminReSendPassword(id: $adminId) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on AdminResendPassword {
      sent
    }
  }
}
    ${ProblemFragmentFragmentDoc}`;
export type AdminPasswordResetMutationFn = Apollo.MutationFunction<AdminPasswordResetMutation, AdminPasswordResetMutationVariables>;

/**
 * __useAdminPasswordResetMutation__
 *
 * To run a mutation, you first call `useAdminPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPasswordResetMutation, { data, loading, error }] = useAdminPasswordResetMutation({
 *   variables: {
 *      adminId: // value for 'adminId'
 *   },
 * });
 */
export function useAdminPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminPasswordResetMutation, AdminPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AdminPasswordResetMutation, AdminPasswordResetMutationVariables>(AdminPasswordResetDocument, options);
      }
export type AdminPasswordResetMutationHookResult = ReturnType<typeof useAdminPasswordResetMutation>;
export type AdminPasswordResetMutationResult = Apollo.MutationResult<AdminPasswordResetMutation>;
export type AdminPasswordResetMutationOptions = Apollo.BaseMutationOptions<AdminPasswordResetMutation, AdminPasswordResetMutationVariables>;
export const AdminDocument = gql`
    query admin($id: ID!) {
  admin(id: $id) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on AdminModel {
      ...AdminFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${AdminFragmentFragmentDoc}`;

/**
 * __useAdminQuery__
 *
 * To run a query within a React component, call `useAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AdminQuery, AdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AdminQuery, AdminQueryVariables>(AdminDocument, options);
      }
export function useAdminLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminQuery, AdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AdminQuery, AdminQueryVariables>(AdminDocument, options);
        }
export type AdminQueryHookResult = ReturnType<typeof useAdminQuery>;
export type AdminLazyQueryHookResult = ReturnType<typeof useAdminLazyQuery>;
export type AdminQueryResult = Apollo.QueryResult<AdminQuery, AdminQueryVariables>;
export const AssetCreateDocument = gql`
    mutation assetCreate($assetCreateInput: AssetCreateInput!) {
  assetCreate(assetCreateInput: $assetCreateInput) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on AssetModel {
      ...AssetFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${AssetFragmentFragmentDoc}`;
export type AssetCreateMutationFn = Apollo.MutationFunction<AssetCreateMutation, AssetCreateMutationVariables>;

/**
 * __useAssetCreateMutation__
 *
 * To run a mutation, you first call `useAssetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetCreateMutation, { data, loading, error }] = useAssetCreateMutation({
 *   variables: {
 *      assetCreateInput: // value for 'assetCreateInput'
 *   },
 * });
 */
export function useAssetCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssetCreateMutation, AssetCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssetCreateMutation, AssetCreateMutationVariables>(AssetCreateDocument, options);
      }
export type AssetCreateMutationHookResult = ReturnType<typeof useAssetCreateMutation>;
export type AssetCreateMutationResult = Apollo.MutationResult<AssetCreateMutation>;
export type AssetCreateMutationOptions = Apollo.BaseMutationOptions<AssetCreateMutation, AssetCreateMutationVariables>;
export const BadgeAssetUploadCreateDocument = gql`
    mutation badgeAssetUploadCreate($input: AssetUploadCreateInput!) {
  badgeAssetUploadCreate(assetUploadCreateInput: $input) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on AssetUploadCreate {
      ...AssetUploadCreateFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${AssetUploadCreateFragmentFragmentDoc}`;
export type BadgeAssetUploadCreateMutationFn = Apollo.MutationFunction<BadgeAssetUploadCreateMutation, BadgeAssetUploadCreateMutationVariables>;

/**
 * __useBadgeAssetUploadCreateMutation__
 *
 * To run a mutation, you first call `useBadgeAssetUploadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBadgeAssetUploadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [badgeAssetUploadCreateMutation, { data, loading, error }] = useBadgeAssetUploadCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBadgeAssetUploadCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BadgeAssetUploadCreateMutation, BadgeAssetUploadCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BadgeAssetUploadCreateMutation, BadgeAssetUploadCreateMutationVariables>(BadgeAssetUploadCreateDocument, options);
      }
export type BadgeAssetUploadCreateMutationHookResult = ReturnType<typeof useBadgeAssetUploadCreateMutation>;
export type BadgeAssetUploadCreateMutationResult = Apollo.MutationResult<BadgeAssetUploadCreateMutation>;
export type BadgeAssetUploadCreateMutationOptions = Apollo.BaseMutationOptions<BadgeAssetUploadCreateMutation, BadgeAssetUploadCreateMutationVariables>;
export const BadgeCreateDocument = gql`
    mutation badgeCreate($input: BadgeCreateInput!) {
  badgeCreate(badgeCreateInput: $input) {
    ... on BadgeModel {
      ...BadgeFragment
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${BadgeFragmentFragmentDoc}
${ProblemFragmentFragmentDoc}`;
export type BadgeCreateMutationFn = Apollo.MutationFunction<BadgeCreateMutation, BadgeCreateMutationVariables>;

/**
 * __useBadgeCreateMutation__
 *
 * To run a mutation, you first call `useBadgeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBadgeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [badgeCreateMutation, { data, loading, error }] = useBadgeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBadgeCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BadgeCreateMutation, BadgeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BadgeCreateMutation, BadgeCreateMutationVariables>(BadgeCreateDocument, options);
      }
export type BadgeCreateMutationHookResult = ReturnType<typeof useBadgeCreateMutation>;
export type BadgeCreateMutationResult = Apollo.MutationResult<BadgeCreateMutation>;
export type BadgeCreateMutationOptions = Apollo.BaseMutationOptions<BadgeCreateMutation, BadgeCreateMutationVariables>;
export const BadgeEditDocument = gql`
    mutation badgeEdit($id: ID!, $input: BadgeEditInput!) {
  badgeEdit(badgeEditInput: $input, id: $id) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on BadgeModel {
      ...BadgeFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BadgeFragmentFragmentDoc}`;
export type BadgeEditMutationFn = Apollo.MutationFunction<BadgeEditMutation, BadgeEditMutationVariables>;

/**
 * __useBadgeEditMutation__
 *
 * To run a mutation, you first call `useBadgeEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBadgeEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [badgeEditMutation, { data, loading, error }] = useBadgeEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBadgeEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BadgeEditMutation, BadgeEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BadgeEditMutation, BadgeEditMutationVariables>(BadgeEditDocument, options);
      }
export type BadgeEditMutationHookResult = ReturnType<typeof useBadgeEditMutation>;
export type BadgeEditMutationResult = Apollo.MutationResult<BadgeEditMutation>;
export type BadgeEditMutationOptions = Apollo.BaseMutationOptions<BadgeEditMutation, BadgeEditMutationVariables>;
export const BadgeFilteredQueryDocument = gql`
    query BadgeFilteredQuery($page: Int!, $take: Int!, $filter: BadgeFilterInput!) {
  badgeListFiltered(badgeFilterInput: $filter, page: $page, take: $take) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BadgeConnection {
      edges {
        node {
          ...BadgeFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BadgeFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useBadgeFilteredQueryQuery__
 *
 * To run a query within a React component, call `useBadgeFilteredQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgeFilteredQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgeFilteredQueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBadgeFilteredQueryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BadgeFilteredQueryQuery, BadgeFilteredQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BadgeFilteredQueryQuery, BadgeFilteredQueryQueryVariables>(BadgeFilteredQueryDocument, options);
      }
export function useBadgeFilteredQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BadgeFilteredQueryQuery, BadgeFilteredQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BadgeFilteredQueryQuery, BadgeFilteredQueryQueryVariables>(BadgeFilteredQueryDocument, options);
        }
export type BadgeFilteredQueryQueryHookResult = ReturnType<typeof useBadgeFilteredQueryQuery>;
export type BadgeFilteredQueryLazyQueryHookResult = ReturnType<typeof useBadgeFilteredQueryLazyQuery>;
export type BadgeFilteredQueryQueryResult = Apollo.QueryResult<BadgeFilteredQueryQuery, BadgeFilteredQueryQueryVariables>;
export const BadgeListDocument = gql`
    query badgeList {
  badgeList {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BadgeListModel {
      badges {
        ...BadgeFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BadgeFragmentFragmentDoc}`;

/**
 * __useBadgeListQuery__
 *
 * To run a query within a React component, call `useBadgeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBadgeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BadgeListQuery, BadgeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BadgeListQuery, BadgeListQueryVariables>(BadgeListDocument, options);
      }
export function useBadgeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BadgeListQuery, BadgeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BadgeListQuery, BadgeListQueryVariables>(BadgeListDocument, options);
        }
export type BadgeListQueryHookResult = ReturnType<typeof useBadgeListQuery>;
export type BadgeListLazyQueryHookResult = ReturnType<typeof useBadgeListLazyQuery>;
export type BadgeListQueryResult = Apollo.QueryResult<BadgeListQuery, BadgeListQueryVariables>;
export const BadgeDocument = gql`
    query badge($id: ID!) {
  badge(id: $id) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on BadgeModel {
      ...BadgeFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BadgeFragmentFragmentDoc}`;

/**
 * __useBadgeQuery__
 *
 * To run a query within a React component, call `useBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBadgeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BadgeQuery, BadgeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BadgeQuery, BadgeQueryVariables>(BadgeDocument, options);
      }
export function useBadgeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BadgeQuery, BadgeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BadgeQuery, BadgeQueryVariables>(BadgeDocument, options);
        }
export type BadgeQueryHookResult = ReturnType<typeof useBadgeQuery>;
export type BadgeLazyQueryHookResult = ReturnType<typeof useBadgeLazyQuery>;
export type BadgeQueryResult = Apollo.QueryResult<BadgeQuery, BadgeQueryVariables>;
export const BadgeRemoveDocument = gql`
    mutation badgeRemove($badgeId: ID!) {
  badgeRemove(id: $badgeId) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdsWithBadge {
      birds {
        ...BirdFragment
      }
    }
    ... on RemoveOperation {
      ...RemoveOperationFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdFragmentFragmentDoc}
${RemoveOperationFragmentFragmentDoc}`;
export type BadgeRemoveMutationFn = Apollo.MutationFunction<BadgeRemoveMutation, BadgeRemoveMutationVariables>;

/**
 * __useBadgeRemoveMutation__
 *
 * To run a mutation, you first call `useBadgeRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBadgeRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [badgeRemoveMutation, { data, loading, error }] = useBadgeRemoveMutation({
 *   variables: {
 *      badgeId: // value for 'badgeId'
 *   },
 * });
 */
export function useBadgeRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BadgeRemoveMutation, BadgeRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BadgeRemoveMutation, BadgeRemoveMutationVariables>(BadgeRemoveDocument, options);
      }
export type BadgeRemoveMutationHookResult = ReturnType<typeof useBadgeRemoveMutation>;
export type BadgeRemoveMutationResult = Apollo.MutationResult<BadgeRemoveMutation>;
export type BadgeRemoveMutationOptions = Apollo.BaseMutationOptions<BadgeRemoveMutation, BadgeRemoveMutationVariables>;
export const BadgeTranslationEditDocument = gql`
    mutation badgeTranslationEdit($badgeId: ID!, $languageId: ID!, $badgeTranslationEditInput: BadgeTranslationEditInput!) {
  badgeTranslationEdit(
    badgeId: $badgeId
    languageId: $languageId
    badgeTranslationEditInput: $badgeTranslationEditInput
  ) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on BadgeTranslationModel {
      ...BadgeTranslationFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BadgeTranslationFragmentFragmentDoc}`;
export type BadgeTranslationEditMutationFn = Apollo.MutationFunction<BadgeTranslationEditMutation, BadgeTranslationEditMutationVariables>;

/**
 * __useBadgeTranslationEditMutation__
 *
 * To run a mutation, you first call `useBadgeTranslationEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBadgeTranslationEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [badgeTranslationEditMutation, { data, loading, error }] = useBadgeTranslationEditMutation({
 *   variables: {
 *      badgeId: // value for 'badgeId'
 *      languageId: // value for 'languageId'
 *      badgeTranslationEditInput: // value for 'badgeTranslationEditInput'
 *   },
 * });
 */
export function useBadgeTranslationEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BadgeTranslationEditMutation, BadgeTranslationEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BadgeTranslationEditMutation, BadgeTranslationEditMutationVariables>(BadgeTranslationEditDocument, options);
      }
export type BadgeTranslationEditMutationHookResult = ReturnType<typeof useBadgeTranslationEditMutation>;
export type BadgeTranslationEditMutationResult = Apollo.MutationResult<BadgeTranslationEditMutation>;
export type BadgeTranslationEditMutationOptions = Apollo.BaseMutationOptions<BadgeTranslationEditMutation, BadgeTranslationEditMutationVariables>;
export const BirdAssetUploadCreateDocument = gql`
    mutation birdAssetUploadCreate($birdAssetUploadCreateInput: BirdAssetUploadCreateInput!) {
  birdAssetUploadCreate(birdAssetUploadCreateInput: $birdAssetUploadCreateInput) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on AssetUploadCreate {
      ...AssetUploadCreateFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${AssetUploadCreateFragmentFragmentDoc}`;
export type BirdAssetUploadCreateMutationFn = Apollo.MutationFunction<BirdAssetUploadCreateMutation, BirdAssetUploadCreateMutationVariables>;

/**
 * __useBirdAssetUploadCreateMutation__
 *
 * To run a mutation, you first call `useBirdAssetUploadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBirdAssetUploadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [birdAssetUploadCreateMutation, { data, loading, error }] = useBirdAssetUploadCreateMutation({
 *   variables: {
 *      birdAssetUploadCreateInput: // value for 'birdAssetUploadCreateInput'
 *   },
 * });
 */
export function useBirdAssetUploadCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BirdAssetUploadCreateMutation, BirdAssetUploadCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BirdAssetUploadCreateMutation, BirdAssetUploadCreateMutationVariables>(BirdAssetUploadCreateDocument, options);
      }
export type BirdAssetUploadCreateMutationHookResult = ReturnType<typeof useBirdAssetUploadCreateMutation>;
export type BirdAssetUploadCreateMutationResult = Apollo.MutationResult<BirdAssetUploadCreateMutation>;
export type BirdAssetUploadCreateMutationOptions = Apollo.BaseMutationOptions<BirdAssetUploadCreateMutation, BirdAssetUploadCreateMutationVariables>;
export const BirdCreateDocument = gql`
    mutation birdCreate($birdCreateInput: BirdCreateInput!) {
  birdCreate(birdCreateInput: $birdCreateInput) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdModel {
      ...BirdFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdFragmentFragmentDoc}`;
export type BirdCreateMutationFn = Apollo.MutationFunction<BirdCreateMutation, BirdCreateMutationVariables>;

/**
 * __useBirdCreateMutation__
 *
 * To run a mutation, you first call `useBirdCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBirdCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [birdCreateMutation, { data, loading, error }] = useBirdCreateMutation({
 *   variables: {
 *      birdCreateInput: // value for 'birdCreateInput'
 *   },
 * });
 */
export function useBirdCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BirdCreateMutation, BirdCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BirdCreateMutation, BirdCreateMutationVariables>(BirdCreateDocument, options);
      }
export type BirdCreateMutationHookResult = ReturnType<typeof useBirdCreateMutation>;
export type BirdCreateMutationResult = Apollo.MutationResult<BirdCreateMutation>;
export type BirdCreateMutationOptions = Apollo.BaseMutationOptions<BirdCreateMutation, BirdCreateMutationVariables>;
export const BirdEditDocument = gql`
    mutation birdEdit($birdId: ID!, $birdEditInput: BirdEditInput!) {
  birdEdit(id: $birdId, birdEditInput: $birdEditInput) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdModel {
      ...BirdFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdFragmentFragmentDoc}`;
export type BirdEditMutationFn = Apollo.MutationFunction<BirdEditMutation, BirdEditMutationVariables>;

/**
 * __useBirdEditMutation__
 *
 * To run a mutation, you first call `useBirdEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBirdEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [birdEditMutation, { data, loading, error }] = useBirdEditMutation({
 *   variables: {
 *      birdId: // value for 'birdId'
 *      birdEditInput: // value for 'birdEditInput'
 *   },
 * });
 */
export function useBirdEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BirdEditMutation, BirdEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BirdEditMutation, BirdEditMutationVariables>(BirdEditDocument, options);
      }
export type BirdEditMutationHookResult = ReturnType<typeof useBirdEditMutation>;
export type BirdEditMutationResult = Apollo.MutationResult<BirdEditMutation>;
export type BirdEditMutationOptions = Apollo.BaseMutationOptions<BirdEditMutation, BirdEditMutationVariables>;
export const BirdFilteredQueryDocument = gql`
    query BirdFilteredQuery($page: Int! = 1, $take: Int! = 15, $birdFindInput: BirdFindInput! = {}) {
  birdFind(birdFindInput: $birdFindInput, page: $page, take: $take) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdConnection {
      edges {
        node {
          ...BirdFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useBirdFilteredQueryQuery__
 *
 * To run a query within a React component, call `useBirdFilteredQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBirdFilteredQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBirdFilteredQueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      birdFindInput: // value for 'birdFindInput'
 *   },
 * });
 */
export function useBirdFilteredQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BirdFilteredQueryQuery, BirdFilteredQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BirdFilteredQueryQuery, BirdFilteredQueryQueryVariables>(BirdFilteredQueryDocument, options);
      }
export function useBirdFilteredQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BirdFilteredQueryQuery, BirdFilteredQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BirdFilteredQueryQuery, BirdFilteredQueryQueryVariables>(BirdFilteredQueryDocument, options);
        }
export type BirdFilteredQueryQueryHookResult = ReturnType<typeof useBirdFilteredQueryQuery>;
export type BirdFilteredQueryLazyQueryHookResult = ReturnType<typeof useBirdFilteredQueryLazyQuery>;
export type BirdFilteredQueryQueryResult = Apollo.QueryResult<BirdFilteredQueryQuery, BirdFilteredQueryQueryVariables>;
export const BirdDocument = gql`
    query bird($id: ID!) {
  bird(id: $id) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdModel {
      ...BirdFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdFragmentFragmentDoc}`;

/**
 * __useBirdQuery__
 *
 * To run a query within a React component, call `useBirdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBirdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBirdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBirdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BirdQuery, BirdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BirdQuery, BirdQueryVariables>(BirdDocument, options);
      }
export function useBirdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BirdQuery, BirdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BirdQuery, BirdQueryVariables>(BirdDocument, options);
        }
export type BirdQueryHookResult = ReturnType<typeof useBirdQuery>;
export type BirdLazyQueryHookResult = ReturnType<typeof useBirdLazyQuery>;
export type BirdQueryResult = Apollo.QueryResult<BirdQuery, BirdQueryVariables>;
export const BirdRemoveDocument = gql`
    mutation birdRemove($birdId: ID!) {
  birdRemove(id: $birdId) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on RemoveOperation {
      ...RemoveOperationFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${RemoveOperationFragmentFragmentDoc}`;
export type BirdRemoveMutationFn = Apollo.MutationFunction<BirdRemoveMutation, BirdRemoveMutationVariables>;

/**
 * __useBirdRemoveMutation__
 *
 * To run a mutation, you first call `useBirdRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBirdRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [birdRemoveMutation, { data, loading, error }] = useBirdRemoveMutation({
 *   variables: {
 *      birdId: // value for 'birdId'
 *   },
 * });
 */
export function useBirdRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BirdRemoveMutation, BirdRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BirdRemoveMutation, BirdRemoveMutationVariables>(BirdRemoveDocument, options);
      }
export type BirdRemoveMutationHookResult = ReturnType<typeof useBirdRemoveMutation>;
export type BirdRemoveMutationResult = Apollo.MutationResult<BirdRemoveMutation>;
export type BirdRemoveMutationOptions = Apollo.BaseMutationOptions<BirdRemoveMutation, BirdRemoveMutationVariables>;
export const BirdSearchDocument = gql`
    query BirdSearch($birdSearchInput: BirdSearchInput! = {}) {
  birdSearch(birdSearchInput: $birdSearchInput) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdSearchModel {
      birds {
        ...BirdSearchFields
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdSearchFieldsFragmentDoc}`;

/**
 * __useBirdSearchQuery__
 *
 * To run a query within a React component, call `useBirdSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBirdSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBirdSearchQuery({
 *   variables: {
 *      birdSearchInput: // value for 'birdSearchInput'
 *   },
 * });
 */
export function useBirdSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BirdSearchQuery, BirdSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BirdSearchQuery, BirdSearchQueryVariables>(BirdSearchDocument, options);
      }
export function useBirdSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BirdSearchQuery, BirdSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BirdSearchQuery, BirdSearchQueryVariables>(BirdSearchDocument, options);
        }
export type BirdSearchQueryHookResult = ReturnType<typeof useBirdSearchQuery>;
export type BirdSearchLazyQueryHookResult = ReturnType<typeof useBirdSearchLazyQuery>;
export type BirdSearchQueryResult = Apollo.QueryResult<BirdSearchQuery, BirdSearchQueryVariables>;
export const BirdSuggestionQueryDocument = gql`
    query BirdSuggestionQuery($page: Int! = 1, $take: Int! = 10, $birdFindInput: BirdFindInput! = {}) {
  birdFind(birdFindInput: $birdFindInput, page: $page, take: $take) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdConnection {
      edges {
        node {
          ...BirdSuggestionFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdSuggestionFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useBirdSuggestionQueryQuery__
 *
 * To run a query within a React component, call `useBirdSuggestionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBirdSuggestionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBirdSuggestionQueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      birdFindInput: // value for 'birdFindInput'
 *   },
 * });
 */
export function useBirdSuggestionQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BirdSuggestionQueryQuery, BirdSuggestionQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BirdSuggestionQueryQuery, BirdSuggestionQueryQueryVariables>(BirdSuggestionQueryDocument, options);
      }
export function useBirdSuggestionQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BirdSuggestionQueryQuery, BirdSuggestionQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BirdSuggestionQueryQuery, BirdSuggestionQueryQueryVariables>(BirdSuggestionQueryDocument, options);
        }
export type BirdSuggestionQueryQueryHookResult = ReturnType<typeof useBirdSuggestionQueryQuery>;
export type BirdSuggestionQueryLazyQueryHookResult = ReturnType<typeof useBirdSuggestionQueryLazyQuery>;
export type BirdSuggestionQueryQueryResult = Apollo.QueryResult<BirdSuggestionQueryQuery, BirdSuggestionQueryQueryVariables>;
export const BirdTranslationEditDocument = gql`
    mutation birdTranslationEdit($birdId: ID!, $languageId: ID!, $birdTranslationEditInput: BirdTranslationEditInput!) {
  birdTranslationEdit(
    id: $birdId
    languageId: $languageId
    birdTranslationEditInput: $birdTranslationEditInput
  ) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdModel {
      ...BirdFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdFragmentFragmentDoc}`;
export type BirdTranslationEditMutationFn = Apollo.MutationFunction<BirdTranslationEditMutation, BirdTranslationEditMutationVariables>;

/**
 * __useBirdTranslationEditMutation__
 *
 * To run a mutation, you first call `useBirdTranslationEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBirdTranslationEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [birdTranslationEditMutation, { data, loading, error }] = useBirdTranslationEditMutation({
 *   variables: {
 *      birdId: // value for 'birdId'
 *      languageId: // value for 'languageId'
 *      birdTranslationEditInput: // value for 'birdTranslationEditInput'
 *   },
 * });
 */
export function useBirdTranslationEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BirdTranslationEditMutation, BirdTranslationEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<BirdTranslationEditMutation, BirdTranslationEditMutationVariables>(BirdTranslationEditDocument, options);
      }
export type BirdTranslationEditMutationHookResult = ReturnType<typeof useBirdTranslationEditMutation>;
export type BirdTranslationEditMutationResult = Apollo.MutationResult<BirdTranslationEditMutation>;
export type BirdTranslationEditMutationOptions = Apollo.BaseMutationOptions<BirdTranslationEditMutation, BirdTranslationEditMutationVariables>;
export const DataExportFileDocument = gql`
    query dataExportFile($dataExportId: ID!, $fileSelector: DataExportType!) {
  dataExportFile(id: $dataExportId, exportFileSelector: $fileSelector) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on DataExportFile {
      fileUrl
    }
  }
}
    ${ProblemFragmentFragmentDoc}`;

/**
 * __useDataExportFileQuery__
 *
 * To run a query within a React component, call `useDataExportFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataExportFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataExportFileQuery({
 *   variables: {
 *      dataExportId: // value for 'dataExportId'
 *      fileSelector: // value for 'fileSelector'
 *   },
 * });
 */
export function useDataExportFileQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DataExportFileQuery, DataExportFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DataExportFileQuery, DataExportFileQueryVariables>(DataExportFileDocument, options);
      }
export function useDataExportFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataExportFileQuery, DataExportFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DataExportFileQuery, DataExportFileQueryVariables>(DataExportFileDocument, options);
        }
export type DataExportFileQueryHookResult = ReturnType<typeof useDataExportFileQuery>;
export type DataExportFileLazyQueryHookResult = ReturnType<typeof useDataExportFileLazyQuery>;
export type DataExportFileQueryResult = Apollo.QueryResult<DataExportFileQuery, DataExportFileQueryVariables>;
export const DataExportsListDocument = gql`
    query dataExportsList($page: Int!, $take: Int!) {
  dataExportList(page: $page, take: $take) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on DataExportConnection {
      edges {
        node {
          ...DataExportFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${DataExportFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useDataExportsListQuery__
 *
 * To run a query within a React component, call `useDataExportsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataExportsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataExportsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useDataExportsListQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DataExportsListQuery, DataExportsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DataExportsListQuery, DataExportsListQueryVariables>(DataExportsListDocument, options);
      }
export function useDataExportsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataExportsListQuery, DataExportsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DataExportsListQuery, DataExportsListQueryVariables>(DataExportsListDocument, options);
        }
export type DataExportsListQueryHookResult = ReturnType<typeof useDataExportsListQuery>;
export type DataExportsListLazyQueryHookResult = ReturnType<typeof useDataExportsListLazyQuery>;
export type DataExportsListQueryResult = Apollo.QueryResult<DataExportsListQuery, DataExportsListQueryVariables>;
export const DataExportScheduleDocument = gql`
    mutation dataExportSchedule {
  dataExportSchedule {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on DataExportModel {
      ...DataExportFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${DataExportFragmentFragmentDoc}`;
export type DataExportScheduleMutationFn = Apollo.MutationFunction<DataExportScheduleMutation, DataExportScheduleMutationVariables>;

/**
 * __useDataExportScheduleMutation__
 *
 * To run a mutation, you first call `useDataExportScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataExportScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataExportScheduleMutation, { data, loading, error }] = useDataExportScheduleMutation({
 *   variables: {
 *   },
 * });
 */
export function useDataExportScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DataExportScheduleMutation, DataExportScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DataExportScheduleMutation, DataExportScheduleMutationVariables>(DataExportScheduleDocument, options);
      }
export type DataExportScheduleMutationHookResult = ReturnType<typeof useDataExportScheduleMutation>;
export type DataExportScheduleMutationResult = Apollo.MutationResult<DataExportScheduleMutation>;
export type DataExportScheduleMutationOptions = Apollo.BaseMutationOptions<DataExportScheduleMutation, DataExportScheduleMutationVariables>;
export const MediaReviewExportDocument = gql`
    mutation mediaReviewExport($exportFilter: MediaReviewExportFilter!) {
  mediaReviewExport(mediaReviewExportInput: {exportFilter: $exportFilter}) {
    ... on MediaReviewExportModel {
      path
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}`;
export type MediaReviewExportMutationFn = Apollo.MutationFunction<MediaReviewExportMutation, MediaReviewExportMutationVariables>;

/**
 * __useMediaReviewExportMutation__
 *
 * To run a mutation, you first call `useMediaReviewExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaReviewExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaReviewExportMutation, { data, loading, error }] = useMediaReviewExportMutation({
 *   variables: {
 *      exportFilter: // value for 'exportFilter'
 *   },
 * });
 */
export function useMediaReviewExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MediaReviewExportMutation, MediaReviewExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MediaReviewExportMutation, MediaReviewExportMutationVariables>(MediaReviewExportDocument, options);
      }
export type MediaReviewExportMutationHookResult = ReturnType<typeof useMediaReviewExportMutation>;
export type MediaReviewExportMutationResult = Apollo.MutationResult<MediaReviewExportMutation>;
export type MediaReviewExportMutationOptions = Apollo.BaseMutationOptions<MediaReviewExportMutation, MediaReviewExportMutationVariables>;
export const FoodAssetUploadCreateDocument = gql`
    mutation foodAssetUploadCreate($input: AssetUploadCreateInput!) {
  foodAssetUploadCreate(assetUploadCreateInput: $input) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on AssetUploadCreate {
      ...AssetUploadCreateFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${AssetUploadCreateFragmentFragmentDoc}`;
export type FoodAssetUploadCreateMutationFn = Apollo.MutationFunction<FoodAssetUploadCreateMutation, FoodAssetUploadCreateMutationVariables>;

/**
 * __useFoodAssetUploadCreateMutation__
 *
 * To run a mutation, you first call `useFoodAssetUploadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodAssetUploadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodAssetUploadCreateMutation, { data, loading, error }] = useFoodAssetUploadCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFoodAssetUploadCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FoodAssetUploadCreateMutation, FoodAssetUploadCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<FoodAssetUploadCreateMutation, FoodAssetUploadCreateMutationVariables>(FoodAssetUploadCreateDocument, options);
      }
export type FoodAssetUploadCreateMutationHookResult = ReturnType<typeof useFoodAssetUploadCreateMutation>;
export type FoodAssetUploadCreateMutationResult = Apollo.MutationResult<FoodAssetUploadCreateMutation>;
export type FoodAssetUploadCreateMutationOptions = Apollo.BaseMutationOptions<FoodAssetUploadCreateMutation, FoodAssetUploadCreateMutationVariables>;
export const FoodCreateDocument = gql`
    mutation foodCreate($input: FoodCreateInput!) {
  foodCreate(foodCreateInput: $input) {
    ... on FoodModel {
      ...FoodFragment
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${FoodFragmentFragmentDoc}
${ProblemFragmentFragmentDoc}`;
export type FoodCreateMutationFn = Apollo.MutationFunction<FoodCreateMutation, FoodCreateMutationVariables>;

/**
 * __useFoodCreateMutation__
 *
 * To run a mutation, you first call `useFoodCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodCreateMutation, { data, loading, error }] = useFoodCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFoodCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FoodCreateMutation, FoodCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<FoodCreateMutation, FoodCreateMutationVariables>(FoodCreateDocument, options);
      }
export type FoodCreateMutationHookResult = ReturnType<typeof useFoodCreateMutation>;
export type FoodCreateMutationResult = Apollo.MutationResult<FoodCreateMutation>;
export type FoodCreateMutationOptions = Apollo.BaseMutationOptions<FoodCreateMutation, FoodCreateMutationVariables>;
export const FoodEditDocument = gql`
    mutation foodEdit($id: ID!, $input: FoodEditInput!) {
  foodEdit(foodEditInput: $input, id: $id) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on FoodModel {
      ...FoodFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${FoodFragmentFragmentDoc}`;
export type FoodEditMutationFn = Apollo.MutationFunction<FoodEditMutation, FoodEditMutationVariables>;

/**
 * __useFoodEditMutation__
 *
 * To run a mutation, you first call `useFoodEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodEditMutation, { data, loading, error }] = useFoodEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFoodEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FoodEditMutation, FoodEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<FoodEditMutation, FoodEditMutationVariables>(FoodEditDocument, options);
      }
export type FoodEditMutationHookResult = ReturnType<typeof useFoodEditMutation>;
export type FoodEditMutationResult = Apollo.MutationResult<FoodEditMutation>;
export type FoodEditMutationOptions = Apollo.BaseMutationOptions<FoodEditMutation, FoodEditMutationVariables>;
export const FoodFilteredQueryDocument = gql`
    query FoodFilteredQuery($page: Int!, $take: Int!, $filter: FoodFilterInput!) {
  foodListFiltered(foodFilterInput: $filter, page: $page, take: $take) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on FoodConnection {
      edges {
        node {
          ...FoodFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${FoodFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useFoodFilteredQueryQuery__
 *
 * To run a query within a React component, call `useFoodFilteredQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodFilteredQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodFilteredQueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFoodFilteredQueryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FoodFilteredQueryQuery, FoodFilteredQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FoodFilteredQueryQuery, FoodFilteredQueryQueryVariables>(FoodFilteredQueryDocument, options);
      }
export function useFoodFilteredQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodFilteredQueryQuery, FoodFilteredQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FoodFilteredQueryQuery, FoodFilteredQueryQueryVariables>(FoodFilteredQueryDocument, options);
        }
export type FoodFilteredQueryQueryHookResult = ReturnType<typeof useFoodFilteredQueryQuery>;
export type FoodFilteredQueryLazyQueryHookResult = ReturnType<typeof useFoodFilteredQueryLazyQuery>;
export type FoodFilteredQueryQueryResult = Apollo.QueryResult<FoodFilteredQueryQuery, FoodFilteredQueryQueryVariables>;
export const FoodListDocument = gql`
    query foodList {
  foodList {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on FoodListModel {
      foods {
        ...FoodFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${FoodFragmentFragmentDoc}`;

/**
 * __useFoodListQuery__
 *
 * To run a query within a React component, call `useFoodListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFoodListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodListQuery, FoodListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FoodListQuery, FoodListQueryVariables>(FoodListDocument, options);
      }
export function useFoodListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodListQuery, FoodListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FoodListQuery, FoodListQueryVariables>(FoodListDocument, options);
        }
export type FoodListQueryHookResult = ReturnType<typeof useFoodListQuery>;
export type FoodListLazyQueryHookResult = ReturnType<typeof useFoodListLazyQuery>;
export type FoodListQueryResult = Apollo.QueryResult<FoodListQuery, FoodListQueryVariables>;
export const FoodDocument = gql`
    query food($id: ID!) {
  food(id: $id) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on FoodModel {
      ...FoodFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${FoodFragmentFragmentDoc}`;

/**
 * __useFoodQuery__
 *
 * To run a query within a React component, call `useFoodQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoodQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FoodQuery, FoodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FoodQuery, FoodQueryVariables>(FoodDocument, options);
      }
export function useFoodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodQuery, FoodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FoodQuery, FoodQueryVariables>(FoodDocument, options);
        }
export type FoodQueryHookResult = ReturnType<typeof useFoodQuery>;
export type FoodLazyQueryHookResult = ReturnType<typeof useFoodLazyQuery>;
export type FoodQueryResult = Apollo.QueryResult<FoodQuery, FoodQueryVariables>;
export const FoodRemoveDocument = gql`
    mutation foodRemove($id: ID!) {
  foodRemove(id: $id) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdsWithFood {
      birds {
        ...BirdFragment
      }
    }
    ... on RemoveOperation {
      ...RemoveOperationFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdFragmentFragmentDoc}
${RemoveOperationFragmentFragmentDoc}`;
export type FoodRemoveMutationFn = Apollo.MutationFunction<FoodRemoveMutation, FoodRemoveMutationVariables>;

/**
 * __useFoodRemoveMutation__
 *
 * To run a mutation, you first call `useFoodRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodRemoveMutation, { data, loading, error }] = useFoodRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoodRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FoodRemoveMutation, FoodRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<FoodRemoveMutation, FoodRemoveMutationVariables>(FoodRemoveDocument, options);
      }
export type FoodRemoveMutationHookResult = ReturnType<typeof useFoodRemoveMutation>;
export type FoodRemoveMutationResult = Apollo.MutationResult<FoodRemoveMutation>;
export type FoodRemoveMutationOptions = Apollo.BaseMutationOptions<FoodRemoveMutation, FoodRemoveMutationVariables>;
export const FoodTranslationEditDocument = gql`
    mutation foodTranslationEdit($foodId: ID!, $languageId: ID!, $foodTranslationEditInput: FoodTranslationEditInput!) {
  foodTranslationEdit(
    id: $foodId
    languageId: $languageId
    foodTranslationEditInput: $foodTranslationEditInput
  ) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on FoodTranslationModel {
      ...FoodTranslationFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${FoodTranslationFragmentFragmentDoc}`;
export type FoodTranslationEditMutationFn = Apollo.MutationFunction<FoodTranslationEditMutation, FoodTranslationEditMutationVariables>;

/**
 * __useFoodTranslationEditMutation__
 *
 * To run a mutation, you first call `useFoodTranslationEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodTranslationEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodTranslationEditMutation, { data, loading, error }] = useFoodTranslationEditMutation({
 *   variables: {
 *      foodId: // value for 'foodId'
 *      languageId: // value for 'languageId'
 *      foodTranslationEditInput: // value for 'foodTranslationEditInput'
 *   },
 * });
 */
export function useFoodTranslationEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FoodTranslationEditMutation, FoodTranslationEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<FoodTranslationEditMutation, FoodTranslationEditMutationVariables>(FoodTranslationEditDocument, options);
      }
export type FoodTranslationEditMutationHookResult = ReturnType<typeof useFoodTranslationEditMutation>;
export type FoodTranslationEditMutationResult = Apollo.MutationResult<FoodTranslationEditMutation>;
export type FoodTranslationEditMutationOptions = Apollo.BaseMutationOptions<FoodTranslationEditMutation, FoodTranslationEditMutationVariables>;
export const GoogleTranslateDocument = gql`
    query googleTranslate($translationCreateInput: TranslationCreateInput!) {
  googleTranslate(translationCreateInput: $translationCreateInput) {
    __typename
    ... on GoogleTranslationModel {
      ...GoogleTranslationFragment
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${GoogleTranslationFragmentFragmentDoc}
${ProblemFragmentFragmentDoc}`;

/**
 * __useGoogleTranslateQuery__
 *
 * To run a query within a React component, call `useGoogleTranslateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleTranslateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleTranslateQuery({
 *   variables: {
 *      translationCreateInput: // value for 'translationCreateInput'
 *   },
 * });
 */
export function useGoogleTranslateQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GoogleTranslateQuery, GoogleTranslateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GoogleTranslateQuery, GoogleTranslateQueryVariables>(GoogleTranslateDocument, options);
      }
export function useGoogleTranslateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoogleTranslateQuery, GoogleTranslateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GoogleTranslateQuery, GoogleTranslateQueryVariables>(GoogleTranslateDocument, options);
        }
export type GoogleTranslateQueryHookResult = ReturnType<typeof useGoogleTranslateQuery>;
export type GoogleTranslateLazyQueryHookResult = ReturnType<typeof useGoogleTranslateLazyQuery>;
export type GoogleTranslateQueryResult = Apollo.QueryResult<GoogleTranslateQuery, GoogleTranslateQueryVariables>;
export const HistoryDocument = gql`
    query history($page: Int!, $take: Int!, $historyFindInput: HistoryFindInput!) {
  historyFind(page: $page, take: $take, historyFindInput: $historyFindInput) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on HistoryConnection {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...HistoryFragment
        }
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}
${HistoryFragmentFragmentDoc}`;

/**
 * __useHistoryQuery__
 *
 * To run a query within a React component, call `useHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      historyFindInput: // value for 'historyFindInput'
 *   },
 * });
 */
export function useHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<HistoryQuery, HistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<HistoryQuery, HistoryQueryVariables>(HistoryDocument, options);
      }
export function useHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HistoryQuery, HistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<HistoryQuery, HistoryQueryVariables>(HistoryDocument, options);
        }
export type HistoryQueryHookResult = ReturnType<typeof useHistoryQuery>;
export type HistoryLazyQueryHookResult = ReturnType<typeof useHistoryLazyQuery>;
export type HistoryQueryResult = Apollo.QueryResult<HistoryQuery, HistoryQueryVariables>;
export const CreatelanguageDocument = gql`
    mutation createlanguage($input: LanguageCreateInput!) {
  languageCreate(languageCreateInput: $input) {
    ... on LanguageModel {
      ...LanguageFragment
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${LanguageFragmentFragmentDoc}
${ProblemFragmentFragmentDoc}`;
export type CreatelanguageMutationFn = Apollo.MutationFunction<CreatelanguageMutation, CreatelanguageMutationVariables>;

/**
 * __useCreatelanguageMutation__
 *
 * To run a mutation, you first call `useCreatelanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatelanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createlanguageMutation, { data, loading, error }] = useCreatelanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatelanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatelanguageMutation, CreatelanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatelanguageMutation, CreatelanguageMutationVariables>(CreatelanguageDocument, options);
      }
export type CreatelanguageMutationHookResult = ReturnType<typeof useCreatelanguageMutation>;
export type CreatelanguageMutationResult = Apollo.MutationResult<CreatelanguageMutation>;
export type CreatelanguageMutationOptions = Apollo.BaseMutationOptions<CreatelanguageMutation, CreatelanguageMutationVariables>;
export const EditLanguageDocument = gql`
    mutation editLanguage($input: LanguageEditInput!, $id: ID!) {
  languageEdit(languageEditInput: $input, id: $id) {
    ... on LanguageModel {
      ...LanguageFragment
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${LanguageFragmentFragmentDoc}
${ProblemFragmentFragmentDoc}`;
export type EditLanguageMutationFn = Apollo.MutationFunction<EditLanguageMutation, EditLanguageMutationVariables>;

/**
 * __useEditLanguageMutation__
 *
 * To run a mutation, you first call `useEditLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLanguageMutation, { data, loading, error }] = useEditLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditLanguageMutation, EditLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditLanguageMutation, EditLanguageMutationVariables>(EditLanguageDocument, options);
      }
export type EditLanguageMutationHookResult = ReturnType<typeof useEditLanguageMutation>;
export type EditLanguageMutationResult = Apollo.MutationResult<EditLanguageMutation>;
export type EditLanguageMutationOptions = Apollo.BaseMutationOptions<EditLanguageMutation, EditLanguageMutationVariables>;
export const LanguageListDocument = gql`
    query LanguageList {
  languageList {
    ... on LanguageListModel {
      languages {
        ...LanguageFragment
      }
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${LanguageFragmentFragmentDoc}
${ProblemFragmentFragmentDoc}`;

/**
 * __useLanguageListQuery__
 *
 * To run a query within a React component, call `useLanguageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageListQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguageListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LanguageListQuery, LanguageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LanguageListQuery, LanguageListQueryVariables>(LanguageListDocument, options);
      }
export function useLanguageListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LanguageListQuery, LanguageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LanguageListQuery, LanguageListQueryVariables>(LanguageListDocument, options);
        }
export type LanguageListQueryHookResult = ReturnType<typeof useLanguageListQuery>;
export type LanguageListLazyQueryHookResult = ReturnType<typeof useLanguageListLazyQuery>;
export type LanguageListQueryResult = Apollo.QueryResult<LanguageListQuery, LanguageListQueryVariables>;
export const LanguageDocument = gql`
    query Language($id: ID!) {
  language(id: $id) {
    ... on LanguageModel {
      ...LanguageFragment
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${LanguageFragmentFragmentDoc}
${ProblemFragmentFragmentDoc}`;

/**
 * __useLanguageQuery__
 *
 * To run a query within a React component, call `useLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLanguageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LanguageQuery, LanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LanguageQuery, LanguageQueryVariables>(LanguageDocument, options);
      }
export function useLanguageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LanguageQuery, LanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LanguageQuery, LanguageQueryVariables>(LanguageDocument, options);
        }
export type LanguageQueryHookResult = ReturnType<typeof useLanguageQuery>;
export type LanguageLazyQueryHookResult = ReturnType<typeof useLanguageLazyQuery>;
export type LanguageQueryResult = Apollo.QueryResult<LanguageQuery, LanguageQueryVariables>;
export const PaginatedLanguagesDocument = gql`
    query PaginatedLanguages($page: Int!, $take: Int!, $filter: LanguageFilterInput!) {
  languageListFiltered(languageFilterInput: $filter, page: $page, take: $take) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on LanguageConnectionModel {
      __typename
      edges {
        node {
          ...LanguageFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${LanguageFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __usePaginatedLanguagesQuery__
 *
 * To run a query within a React component, call `usePaginatedLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedLanguagesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedLanguagesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PaginatedLanguagesQuery, PaginatedLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PaginatedLanguagesQuery, PaginatedLanguagesQueryVariables>(PaginatedLanguagesDocument, options);
      }
export function usePaginatedLanguagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaginatedLanguagesQuery, PaginatedLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PaginatedLanguagesQuery, PaginatedLanguagesQueryVariables>(PaginatedLanguagesDocument, options);
        }
export type PaginatedLanguagesQueryHookResult = ReturnType<typeof usePaginatedLanguagesQuery>;
export type PaginatedLanguagesLazyQueryHookResult = ReturnType<typeof usePaginatedLanguagesLazyQuery>;
export type PaginatedLanguagesQueryResult = Apollo.QueryResult<PaginatedLanguagesQuery, PaginatedLanguagesQueryVariables>;
export const RemoveLanguageDocument = gql`
    mutation removeLanguage($id: ID!) {
  languageRemove(id: $id) {
    __typename
    ... on Problem {
      __typename
    }
  }
}
    `;
export type RemoveLanguageMutationFn = Apollo.MutationFunction<RemoveLanguageMutation, RemoveLanguageMutationVariables>;

/**
 * __useRemoveLanguageMutation__
 *
 * To run a mutation, you first call `useRemoveLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLanguageMutation, { data, loading, error }] = useRemoveLanguageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveLanguageMutation, RemoveLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveLanguageMutation, RemoveLanguageMutationVariables>(RemoveLanguageDocument, options);
      }
export type RemoveLanguageMutationHookResult = ReturnType<typeof useRemoveLanguageMutation>;
export type RemoveLanguageMutationResult = Apollo.MutationResult<RemoveLanguageMutation>;
export type RemoveLanguageMutationOptions = Apollo.BaseMutationOptions<RemoveLanguageMutation, RemoveLanguageMutationVariables>;
export const LocationListQueryDocument = gql`
    query locationListQuery {
  locationList {
    ... on Problem {
      ...ProblemFragment
    }
    ... on LocationListModel {
      locations {
        ...LocationFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${LocationFragmentFragmentDoc}`;

/**
 * __useLocationListQueryQuery__
 *
 * To run a query within a React component, call `useLocationListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationListQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationListQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationListQueryQuery, LocationListQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LocationListQueryQuery, LocationListQueryQueryVariables>(LocationListQueryDocument, options);
      }
export function useLocationListQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationListQueryQuery, LocationListQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LocationListQueryQuery, LocationListQueryQueryVariables>(LocationListQueryDocument, options);
        }
export type LocationListQueryQueryHookResult = ReturnType<typeof useLocationListQueryQuery>;
export type LocationListQueryLazyQueryHookResult = ReturnType<typeof useLocationListQueryLazyQuery>;
export type LocationListQueryQueryResult = Apollo.QueryResult<LocationListQueryQuery, LocationListQueryQueryVariables>;
export const CountriesListDocument = gql`
    query CountriesList {
  countryList {
    ... on CountryListModel {
      countries {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCountriesListQuery__
 *
 * To run a query within a React component, call `useCountriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountriesListQuery, CountriesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CountriesListQuery, CountriesListQueryVariables>(CountriesListDocument, options);
      }
export function useCountriesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountriesListQuery, CountriesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CountriesListQuery, CountriesListQueryVariables>(CountriesListDocument, options);
        }
export type CountriesListQueryHookResult = ReturnType<typeof useCountriesListQuery>;
export type CountriesListLazyQueryHookResult = ReturnType<typeof useCountriesListLazyQuery>;
export type CountriesListQueryResult = Apollo.QueryResult<CountriesListQuery, CountriesListQueryVariables>;
export const MediaReportDecideDocument = gql`
    mutation MediaReportDecide($id: ID!, $decision: DecisionType!) {
  mediaReportDecide(id: $id, decisionType: $decision) {
    ... on MediaReportModel {
      ...MediaReportFields
    }
  }
}
    ${MediaReportFieldsFragmentDoc}`;
export type MediaReportDecideMutationFn = Apollo.MutationFunction<MediaReportDecideMutation, MediaReportDecideMutationVariables>;

/**
 * __useMediaReportDecideMutation__
 *
 * To run a mutation, you first call `useMediaReportDecideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaReportDecideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaReportDecideMutation, { data, loading, error }] = useMediaReportDecideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      decision: // value for 'decision'
 *   },
 * });
 */
export function useMediaReportDecideMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MediaReportDecideMutation, MediaReportDecideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MediaReportDecideMutation, MediaReportDecideMutationVariables>(MediaReportDecideDocument, options);
      }
export type MediaReportDecideMutationHookResult = ReturnType<typeof useMediaReportDecideMutation>;
export type MediaReportDecideMutationResult = Apollo.MutationResult<MediaReportDecideMutation>;
export type MediaReportDecideMutationOptions = Apollo.BaseMutationOptions<MediaReportDecideMutation, MediaReportDecideMutationVariables>;
export const ReportedMediaSummaryDocument = gql`
    query ReportedMediaSummary($id: ID!) {
  mediaReportSummary(id: $id) {
    ... on MediaReportSummaryModel {
      previousMediaReport {
        id
      }
      currentMediaReport {
        ... on MediaReportModel {
          ...MediaReportFields
        }
      }
      nextMediaReport {
        id
      }
      totalCount
    }
  }
}
    ${MediaReportFieldsFragmentDoc}`;

/**
 * __useReportedMediaSummaryQuery__
 *
 * To run a query within a React component, call `useReportedMediaSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportedMediaSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportedMediaSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportedMediaSummaryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReportedMediaSummaryQuery, ReportedMediaSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReportedMediaSummaryQuery, ReportedMediaSummaryQueryVariables>(ReportedMediaSummaryDocument, options);
      }
export function useReportedMediaSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportedMediaSummaryQuery, ReportedMediaSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReportedMediaSummaryQuery, ReportedMediaSummaryQueryVariables>(ReportedMediaSummaryDocument, options);
        }
export type ReportedMediaSummaryQueryHookResult = ReturnType<typeof useReportedMediaSummaryQuery>;
export type ReportedMediaSummaryLazyQueryHookResult = ReturnType<typeof useReportedMediaSummaryLazyQuery>;
export type ReportedMediaSummaryQueryResult = Apollo.QueryResult<ReportedMediaSummaryQuery, ReportedMediaSummaryQueryVariables>;
export const MediaReportHistoryDocument = gql`
    query MediaReportHistory($id: ID!) {
  mediaReportHistory(mediaId: $id) {
    __typename
    ... on Problem {
      ...ProblemFragment
    }
    ... on MediaReportListModel {
      mediaReports {
        ...MediaReportFields
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${MediaReportFieldsFragmentDoc}`;

/**
 * __useMediaReportHistoryQuery__
 *
 * To run a query within a React component, call `useMediaReportHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaReportHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaReportHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaReportHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MediaReportHistoryQuery, MediaReportHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MediaReportHistoryQuery, MediaReportHistoryQueryVariables>(MediaReportHistoryDocument, options);
      }
export function useMediaReportHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MediaReportHistoryQuery, MediaReportHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MediaReportHistoryQuery, MediaReportHistoryQueryVariables>(MediaReportHistoryDocument, options);
        }
export type MediaReportHistoryQueryHookResult = ReturnType<typeof useMediaReportHistoryQuery>;
export type MediaReportHistoryLazyQueryHookResult = ReturnType<typeof useMediaReportHistoryLazyQuery>;
export type MediaReportHistoryQueryResult = Apollo.QueryResult<MediaReportHistoryQuery, MediaReportHistoryQueryVariables>;
export const MediaReviewIdentificationDocument = gql`
    mutation MediaReviewIdentification($mediaId: ID!, $birdIds: [ID!], $reviewerAction: MediaReviewIdentificationAction!) {
  mediaReviewIdentification(
    id: $mediaId
    birdIds: $birdIds
    reviewerAction: $reviewerAction
  ) {
    ... on MediaReviewModel {
      ...MediaReviewIdentificationFields
    }
  }
}
    ${MediaReviewIdentificationFieldsFragmentDoc}`;
export type MediaReviewIdentificationMutationFn = Apollo.MutationFunction<MediaReviewIdentificationMutation, MediaReviewIdentificationMutationVariables>;

/**
 * __useMediaReviewIdentificationMutation__
 *
 * To run a mutation, you first call `useMediaReviewIdentificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaReviewIdentificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaReviewIdentificationMutation, { data, loading, error }] = useMediaReviewIdentificationMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      birdIds: // value for 'birdIds'
 *      reviewerAction: // value for 'reviewerAction'
 *   },
 * });
 */
export function useMediaReviewIdentificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MediaReviewIdentificationMutation, MediaReviewIdentificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MediaReviewIdentificationMutation, MediaReviewIdentificationMutationVariables>(MediaReviewIdentificationDocument, options);
      }
export type MediaReviewIdentificationMutationHookResult = ReturnType<typeof useMediaReviewIdentificationMutation>;
export type MediaReviewIdentificationMutationResult = Apollo.MutationResult<MediaReviewIdentificationMutation>;
export type MediaReviewIdentificationMutationOptions = Apollo.BaseMutationOptions<MediaReviewIdentificationMutation, MediaReviewIdentificationMutationVariables>;
export const MediaReviewSummaryDocument = gql`
    query MediaReviewSummary($id: ID!, $mediaReviewSummaryInput: MediaReviewSummaryInput) {
  mediaReviewSummary(id: $id, mediaReviewSummaryInput: $mediaReviewSummaryInput) {
    ... on MediaReviewSummaryModel {
      previousMediaReview {
        id
      }
      currentMediaReview {
        ... on MediaReviewModel {
          ...MediaReviewFields
        }
      }
      nextMediaReview {
        id
      }
      totalCount
    }
  }
}
    ${MediaReviewFieldsFragmentDoc}`;

/**
 * __useMediaReviewSummaryQuery__
 *
 * To run a query within a React component, call `useMediaReviewSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaReviewSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaReviewSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      mediaReviewSummaryInput: // value for 'mediaReviewSummaryInput'
 *   },
 * });
 */
export function useMediaReviewSummaryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MediaReviewSummaryQuery, MediaReviewSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MediaReviewSummaryQuery, MediaReviewSummaryQueryVariables>(MediaReviewSummaryDocument, options);
      }
export function useMediaReviewSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MediaReviewSummaryQuery, MediaReviewSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MediaReviewSummaryQuery, MediaReviewSummaryQueryVariables>(MediaReviewSummaryDocument, options);
        }
export type MediaReviewSummaryQueryHookResult = ReturnType<typeof useMediaReviewSummaryQuery>;
export type MediaReviewSummaryLazyQueryHookResult = ReturnType<typeof useMediaReviewSummaryLazyQuery>;
export type MediaReviewSummaryQueryResult = Apollo.QueryResult<MediaReviewSummaryQuery, MediaReviewSummaryQueryVariables>;
export const MediaReviewsFilteredDocument = gql`
    query MediaReviewsFiltered($page: Int! = 1, $take: Int! = 15, $filterInput: MediaReviewFilterInput! = {}) {
  mediaReviewList(page: $page, take: $take, mediaReviewFilterInput: $filterInput) {
    ... on MediaReviewConnection {
      edges {
        node {
          ...MediaReviewFields
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${MediaReviewFieldsFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useMediaReviewsFilteredQuery__
 *
 * To run a query within a React component, call `useMediaReviewsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaReviewsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaReviewsFilteredQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useMediaReviewsFilteredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MediaReviewsFilteredQuery, MediaReviewsFilteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MediaReviewsFilteredQuery, MediaReviewsFilteredQueryVariables>(MediaReviewsFilteredDocument, options);
      }
export function useMediaReviewsFilteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MediaReviewsFilteredQuery, MediaReviewsFilteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MediaReviewsFilteredQuery, MediaReviewsFilteredQueryVariables>(MediaReviewsFilteredDocument, options);
        }
export type MediaReviewsFilteredQueryHookResult = ReturnType<typeof useMediaReviewsFilteredQuery>;
export type MediaReviewsFilteredLazyQueryHookResult = ReturnType<typeof useMediaReviewsFilteredLazyQuery>;
export type MediaReviewsFilteredQueryResult = Apollo.QueryResult<MediaReviewsFilteredQuery, MediaReviewsFilteredQueryVariables>;
export const ReportedMediaFilteredDocument = gql`
    query ReportedMediaFiltered($page: Int! = 1, $take: Int! = 15, $mediaFindInput: MediaReportFilterInput! = {}) {
  mediaReportList(
    page: $page
    take: $take
    mediaReportFilterInput: $mediaFindInput
  ) {
    ... on MediaReportConnection {
      edges {
        node {
          ...MediaReportFields
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${MediaReportFieldsFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useReportedMediaFilteredQuery__
 *
 * To run a query within a React component, call `useReportedMediaFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportedMediaFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportedMediaFilteredQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      mediaFindInput: // value for 'mediaFindInput'
 *   },
 * });
 */
export function useReportedMediaFilteredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportedMediaFilteredQuery, ReportedMediaFilteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReportedMediaFilteredQuery, ReportedMediaFilteredQueryVariables>(ReportedMediaFilteredDocument, options);
      }
export function useReportedMediaFilteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportedMediaFilteredQuery, ReportedMediaFilteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReportedMediaFilteredQuery, ReportedMediaFilteredQueryVariables>(ReportedMediaFilteredDocument, options);
        }
export type ReportedMediaFilteredQueryHookResult = ReturnType<typeof useReportedMediaFilteredQuery>;
export type ReportedMediaFilteredLazyQueryHookResult = ReturnType<typeof useReportedMediaFilteredLazyQuery>;
export type ReportedMediaFilteredQueryResult = Apollo.QueryResult<ReportedMediaFilteredQuery, ReportedMediaFilteredQueryVariables>;
export const ReportedMediaDocument = gql`
    query ReportedMedia($id: ID!) {
  mediaReportFind(id: $id) {
    ... on MediaReportModel {
      ...MediaReportFields
    }
  }
}
    ${MediaReportFieldsFragmentDoc}`;

/**
 * __useReportedMediaQuery__
 *
 * To run a query within a React component, call `useReportedMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportedMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportedMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportedMediaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReportedMediaQuery, ReportedMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReportedMediaQuery, ReportedMediaQueryVariables>(ReportedMediaDocument, options);
      }
export function useReportedMediaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportedMediaQuery, ReportedMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReportedMediaQuery, ReportedMediaQueryVariables>(ReportedMediaDocument, options);
        }
export type ReportedMediaQueryHookResult = ReturnType<typeof useReportedMediaQuery>;
export type ReportedMediaLazyQueryHookResult = ReturnType<typeof useReportedMediaLazyQuery>;
export type ReportedMediaQueryResult = Apollo.QueryResult<ReportedMediaQuery, ReportedMediaQueryVariables>;
export const SoundLicenseListFilteredDocument = gql`
    query soundLicenseListFiltered($page: Int!, $take: Int!, $filter: SoundLicenseFilterInput!) {
  soundLicenseListFiltered(
    soundLicenseFilterInput: $filter
    page: $page
    take: $take
  ) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on SoundLicenseConnectionModel {
      __typename
      edges {
        node {
          ...SoundLicenseFields
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${SoundLicenseFieldsFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useSoundLicenseListFilteredQuery__
 *
 * To run a query within a React component, call `useSoundLicenseListFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoundLicenseListFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoundLicenseListFilteredQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSoundLicenseListFilteredQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SoundLicenseListFilteredQuery, SoundLicenseListFilteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SoundLicenseListFilteredQuery, SoundLicenseListFilteredQueryVariables>(SoundLicenseListFilteredDocument, options);
      }
export function useSoundLicenseListFilteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoundLicenseListFilteredQuery, SoundLicenseListFilteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SoundLicenseListFilteredQuery, SoundLicenseListFilteredQueryVariables>(SoundLicenseListFilteredDocument, options);
        }
export type SoundLicenseListFilteredQueryHookResult = ReturnType<typeof useSoundLicenseListFilteredQuery>;
export type SoundLicenseListFilteredLazyQueryHookResult = ReturnType<typeof useSoundLicenseListFilteredLazyQuery>;
export type SoundLicenseListFilteredQueryResult = Apollo.QueryResult<SoundLicenseListFilteredQuery, SoundLicenseListFilteredQueryVariables>;
export const SoundLicenseCreateDocument = gql`
    mutation soundLicenseCreate($input: SoundLicenseCreateInput!) {
  soundLicenseCreate(soundLicenseCreateInput: $input) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on SoundLicenseModel {
      ...SoundLicenseFields
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${SoundLicenseFieldsFragmentDoc}`;
export type SoundLicenseCreateMutationFn = Apollo.MutationFunction<SoundLicenseCreateMutation, SoundLicenseCreateMutationVariables>;

/**
 * __useSoundLicenseCreateMutation__
 *
 * To run a mutation, you first call `useSoundLicenseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoundLicenseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [soundLicenseCreateMutation, { data, loading, error }] = useSoundLicenseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSoundLicenseCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SoundLicenseCreateMutation, SoundLicenseCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SoundLicenseCreateMutation, SoundLicenseCreateMutationVariables>(SoundLicenseCreateDocument, options);
      }
export type SoundLicenseCreateMutationHookResult = ReturnType<typeof useSoundLicenseCreateMutation>;
export type SoundLicenseCreateMutationResult = Apollo.MutationResult<SoundLicenseCreateMutation>;
export type SoundLicenseCreateMutationOptions = Apollo.BaseMutationOptions<SoundLicenseCreateMutation, SoundLicenseCreateMutationVariables>;
export const SoundLicenseEditDocument = gql`
    mutation soundLicenseEdit($input: SoundLicenseEditInput!, $id: ID!) {
  soundLicenseEdit(id: $id, soundLicenseEditInput: $input) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on SoundLicenseModel {
      ...SoundLicenseFields
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${SoundLicenseFieldsFragmentDoc}`;
export type SoundLicenseEditMutationFn = Apollo.MutationFunction<SoundLicenseEditMutation, SoundLicenseEditMutationVariables>;

/**
 * __useSoundLicenseEditMutation__
 *
 * To run a mutation, you first call `useSoundLicenseEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoundLicenseEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [soundLicenseEditMutation, { data, loading, error }] = useSoundLicenseEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoundLicenseEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SoundLicenseEditMutation, SoundLicenseEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SoundLicenseEditMutation, SoundLicenseEditMutationVariables>(SoundLicenseEditDocument, options);
      }
export type SoundLicenseEditMutationHookResult = ReturnType<typeof useSoundLicenseEditMutation>;
export type SoundLicenseEditMutationResult = Apollo.MutationResult<SoundLicenseEditMutation>;
export type SoundLicenseEditMutationOptions = Apollo.BaseMutationOptions<SoundLicenseEditMutation, SoundLicenseEditMutationVariables>;
export const SoundLicenseListDocument = gql`
    query soundLicenseList {
  soundLicenseList {
    ... on Problem {
      ...ProblemFragment
    }
    ... on SoundLicenseListModel {
      soundLicenses {
        id
        name
      }
    }
  }
}
    ${ProblemFragmentFragmentDoc}`;

/**
 * __useSoundLicenseListQuery__
 *
 * To run a query within a React component, call `useSoundLicenseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoundLicenseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoundLicenseListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoundLicenseListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoundLicenseListQuery, SoundLicenseListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SoundLicenseListQuery, SoundLicenseListQueryVariables>(SoundLicenseListDocument, options);
      }
export function useSoundLicenseListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoundLicenseListQuery, SoundLicenseListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SoundLicenseListQuery, SoundLicenseListQueryVariables>(SoundLicenseListDocument, options);
        }
export type SoundLicenseListQueryHookResult = ReturnType<typeof useSoundLicenseListQuery>;
export type SoundLicenseListLazyQueryHookResult = ReturnType<typeof useSoundLicenseListLazyQuery>;
export type SoundLicenseListQueryResult = Apollo.QueryResult<SoundLicenseListQuery, SoundLicenseListQueryVariables>;
export const SoundLicenseDocument = gql`
    query soundLicense($id: ID!) {
  soundLicense(id: $id) {
    ... on SoundLicenseModel {
      ...SoundLicenseFields
    }
    ... on Problem {
      ...ProblemFragment
    }
  }
}
    ${SoundLicenseFieldsFragmentDoc}
${ProblemFragmentFragmentDoc}`;

/**
 * __useSoundLicenseQuery__
 *
 * To run a query within a React component, call `useSoundLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoundLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoundLicenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoundLicenseQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SoundLicenseQuery, SoundLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SoundLicenseQuery, SoundLicenseQueryVariables>(SoundLicenseDocument, options);
      }
export function useSoundLicenseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoundLicenseQuery, SoundLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SoundLicenseQuery, SoundLicenseQueryVariables>(SoundLicenseDocument, options);
        }
export type SoundLicenseQueryHookResult = ReturnType<typeof useSoundLicenseQuery>;
export type SoundLicenseLazyQueryHookResult = ReturnType<typeof useSoundLicenseLazyQuery>;
export type SoundLicenseQueryResult = Apollo.QueryResult<SoundLicenseQuery, SoundLicenseQueryVariables>;
export const SoundLicenseRemoveDocument = gql`
    mutation soundLicenseRemove($id: ID!) {
  soundLicenseRemove(id: $id) {
    ... on Problem {
      ...ProblemFragment
    }
    ... on BirdsWithSoundLicense {
      birds {
        ...BirdFragment
      }
    }
    ... on RemoveOperation {
      ...RemoveOperationFragment
    }
  }
}
    ${ProblemFragmentFragmentDoc}
${BirdFragmentFragmentDoc}
${RemoveOperationFragmentFragmentDoc}`;
export type SoundLicenseRemoveMutationFn = Apollo.MutationFunction<SoundLicenseRemoveMutation, SoundLicenseRemoveMutationVariables>;

/**
 * __useSoundLicenseRemoveMutation__
 *
 * To run a mutation, you first call `useSoundLicenseRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoundLicenseRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [soundLicenseRemoveMutation, { data, loading, error }] = useSoundLicenseRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoundLicenseRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SoundLicenseRemoveMutation, SoundLicenseRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SoundLicenseRemoveMutation, SoundLicenseRemoveMutationVariables>(SoundLicenseRemoveDocument, options);
      }
export type SoundLicenseRemoveMutationHookResult = ReturnType<typeof useSoundLicenseRemoveMutation>;
export type SoundLicenseRemoveMutationResult = Apollo.MutationResult<SoundLicenseRemoveMutation>;
export type SoundLicenseRemoveMutationOptions = Apollo.BaseMutationOptions<SoundLicenseRemoveMutation, SoundLicenseRemoveMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AdminConnectionResult": [
      "AdminConnection",
      "Problem"
    ],
    "AdminReSendPasswordResult": [
      "AdminResendPassword",
      "Problem"
    ],
    "AdminResult": [
      "AdminModel",
      "Problem"
    ],
    "AssetCreateResult": [
      "AssetModel",
      "Problem"
    ],
    "AssetUploadCreateResult": [
      "AssetUploadCreate",
      "Problem"
    ],
    "BadgeConnectionResult": [
      "BadgeConnection",
      "Problem"
    ],
    "BadgeListResult": [
      "BadgeListModel",
      "Problem"
    ],
    "BadgeRemoveResult": [
      "BirdsWithBadge",
      "Problem",
      "RemoveOperation"
    ],
    "BadgeResult": [
      "BadgeModel",
      "Problem"
    ],
    "BadgeTranslationResult": [
      "BadgeTranslationModel",
      "Problem"
    ],
    "BirdConnectionResult": [
      "BirdConnection",
      "Problem"
    ],
    "BirdResult": [
      "BirdModel",
      "Problem"
    ],
    "BirdSearchResult": [
      "BirdSearchModel",
      "Problem"
    ],
    "CountryListResult": [
      "CountryListModel",
      "Problem"
    ],
    "DataExportConnectionResult": [
      "DataExportConnection",
      "Problem"
    ],
    "DataExportFileResult": [
      "DataExportFile",
      "Problem"
    ],
    "DataExportResult": [
      "DataExportModel",
      "Problem"
    ],
    "DeviceInfoResult": [
      "FeederlessDeviceInfoModel",
      "Problem"
    ],
    "DeviceResult": [
      "FeederlessDeviceModel",
      "Problem"
    ],
    "EditorialFeedConnectionResult": [
      "EditorialFeedConnection",
      "Problem"
    ],
    "EditorialFeedResult": [
      "EditorialFeedModel",
      "Problem"
    ],
    "EditorialFeedTagListResult": [
      "EditorialFeedTagListModel",
      "Problem"
    ],
    "EditorialFeedTagResult": [
      "EditorialFeedTagModel",
      "Problem"
    ],
    "FeederlessDeviceTutorialPostcardResult": [
      "FeederlessDeviceTutorialPostcardModel",
      "Problem"
    ],
    "FeederlessPostcardConnectionResult": [
      "FeederlessPostcardConnection",
      "Problem"
    ],
    "FeederlessPostcardResult": [
      "FeederlessPostcardModel",
      "Problem"
    ],
    "FoodConnectionResult": [
      "FoodConnection",
      "Problem"
    ],
    "FoodListResult": [
      "FoodListModel",
      "Problem"
    ],
    "FoodRemoveResult": [
      "BirdsWithFood",
      "Problem",
      "RemoveOperation"
    ],
    "FoodResult": [
      "FoodModel",
      "Problem"
    ],
    "FoodTranslationResult": [
      "FoodTranslationModel",
      "Problem"
    ],
    "GoogleTranslationCreateResult": [
      "GoogleTranslationModel",
      "Problem"
    ],
    "HistoryConnectionResult": [
      "HistoryConnection",
      "Problem"
    ],
    "LanguageConnectionResult": [
      "LanguageConnectionModel",
      "Problem"
    ],
    "LanguageListResult": [
      "LanguageListModel",
      "Problem"
    ],
    "LanguageResult": [
      "LanguageModel",
      "Problem"
    ],
    "LocationListResult": [
      "LocationListModel",
      "Problem"
    ],
    "LocationResult": [
      "LocationModel",
      "Problem"
    ],
    "MeResult": [
      "AdminModel",
      "Problem"
    ],
    "MediaReportConnectionResult": [
      "MediaReportConnection",
      "Problem"
    ],
    "MediaReportListResult": [
      "MediaReportListModel",
      "Problem"
    ],
    "MediaReportResult": [
      "MediaReportModel",
      "Problem"
    ],
    "MediaReportSummaryResult": [
      "MediaReportSummaryModel",
      "Problem"
    ],
    "MediaReviewConnectionResult": [
      "MediaReviewConnection",
      "Problem"
    ],
    "MediaReviewExportResult": [
      "MediaReviewExportModel",
      "Problem"
    ],
    "MediaReviewResult": [
      "MediaReviewModel",
      "Problem"
    ],
    "Node": [
      "AdminModel",
      "AssetModel",
      "BadgeModel",
      "BirdModel",
      "CountryModel",
      "DataExportModel",
      "EditorialFeedMediaModel",
      "EditorialFeedModel",
      "EditorialFeedSpeciesModel",
      "EditorialFeedTagModel",
      "FeederlessDeviceInfoLandscapeModel",
      "FeederlessDeviceInfoModel",
      "FeederlessDeviceModel",
      "FeederlessDeviceTutorialPostcardMediaSpeciesModel",
      "FeederlessDeviceTutorialPostcardModel",
      "FeederlessPostcardMediaModel",
      "FeederlessPostcardModel",
      "FeederlessPostcardSpeciesModel",
      "FoodModel",
      "HistoryModel",
      "LanguageModel",
      "LocationModel",
      "MediaModel",
      "MediaReportModel",
      "MediaReviewBirdModel",
      "MediaReviewConnectionModel",
      "MediaReviewModel",
      "MediaSpeciesModel",
      "SoundLicenseModel",
      "SoundModel",
      "UserModel"
    ],
    "RemoveResult": [
      "Problem",
      "RemoveOperation"
    ],
    "RequestAIBirdResult": [
      "BirdRequestAI",
      "Problem"
    ],
    "RequestNewBirdResult": [
      "NewBirdRequest",
      "Problem"
    ],
    "SoundLicenseConnectionResult": [
      "Problem",
      "SoundLicenseConnectionModel"
    ],
    "SoundLicenseListResult": [
      "Problem",
      "SoundLicenseListModel"
    ],
    "SoundLicenseRemoveResult": [
      "BirdsWithSoundLicense",
      "Problem",
      "RemoveOperation"
    ],
    "SoundLicenseResult": [
      "Problem",
      "SoundLicenseModel"
    ],
    "UserConnectionResult": [
      "Problem",
      "UserConnection"
    ]
  }
};
      export default result;
    