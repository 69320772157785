import React from 'react';
import { Badge } from 'react-bootstrap';
import { MediaReportReason } from 'apollo/graphql/generated';

interface Props {
  reason: MediaReportReason;
}

const ReportedReasonBadge = ({ reason }: Props) => {
  return (
    <>
      {reason === MediaReportReason.InvolvesAPerson && (
        <Badge pill bg={'warning'}>
          Involves a Person
        </Badge>
      )}
      {reason === MediaReportReason.NoBird && (
        <Badge pill bg={'secondary'}>
          No bird on the photo
        </Badge>
      )}
      {reason === MediaReportReason.Nudity && (
        <Badge pill bg={'danger'}>
          Nudity
        </Badge>
      )}
      {reason === MediaReportReason.Other && (
        <Badge pill bg={'primary'}>
          Something else
        </Badge>
      )}
      {reason === MediaReportReason.Violence && (
        <Badge pill bg={'danger'}>
          Violence
        </Badge>
      )}
      {reason === MediaReportReason.WrongBird && (
        <Badge pill bg={'secondary'}>
          Wrong bird
        </Badge>
      )}
      {reason === MediaReportReason.SickBird && (
        <Badge pill bg={'danger'}>
          Sick bird
        </Badge>
      )}
    </>
  );
};

export default ReportedReasonBadge;
