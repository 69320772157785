import React from 'react';
import { useField } from 'formik';
import CustomCheckbox, { CustomCheckboxType } from './CustomCheckbox';

export type ControlledCustomCheckboxProps = {
  label?: string;
  name: string;
  className?: string;
  type?: CustomCheckboxType;
};

const ControlledCustomCheckbox = ({ name, label, className, type = 'checkbox' }: ControlledCustomCheckboxProps) => {
  const [field, meta, helpers] = useField<boolean>({
    name
  });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    helpers.setValue(e.target.checked);
  };

  return (
    <CustomCheckbox
      className={className}
      checked={field.value}
      name={name}
      label={label}
      onChange={onChange}
      isInvalid={!!meta.error}
      type={type}
    />
  );
};

export default ControlledCustomCheckbox;
